import {TextInput} from 'flowbite-react'
import React from 'react'
import {Field} from 'react-final-form'

type Props = {
  name: string
  label?: string
  type?: string
  min?: number
  max?: number
  parse?: (value: string) => any
}

export const InputField = ({label, name, type = 'text', min, max, parse}: Props) => {
  return (
    <Field name={name} parse={parse}>
      {({input, meta}) => (
        <div className="flex flex-col gap-1">
          {label && (
            <label htmlFor="capacity" className="block text-sm font-medium text-gray-700">
              {label}
            </label>
          )}
          <TextInput {...input} type={type} min={min} max={max} sizing="sm" />
          {meta.touched && meta.error && <span>{meta.error}</span>}
        </div>
      )}
    </Field>
  )
}
