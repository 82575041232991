import {SoldoutDaysChart} from '../components/charts/SoldoutDaysChart'
import {SoldoutDaysReport} from '../components/reports/SoldoutDaysReport'
import {SummarySoldoutDaysReport} from '../components/reports/SummarySoldoutDaysReport'

export const SoldoutDays = () => {
  return (
    <>
      <SummarySoldoutDaysReport />
      <SoldoutDaysChart />
      <SoldoutDaysReport />
    </>
  )
}
