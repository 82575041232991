import {useQuery, useQueryClient} from 'react-query'

export const useGetEntityQuery = <Entity, Id>(
  id: Id | undefined,
  getEntity: (id: Id) => Promise<Entity>,
  entityName: string
) => {
  const queryKey = ['get' + entityName, id]

  const query = useQuery(
    queryKey,
    () => {
      if (!id) {
        throw new Error(`Entity ('${entityName}') id is not defined`)
      }
      return getEntity(id)
    },
    {
      enabled: id !== undefined,
    }
  )

  const queryClient = useQueryClient()

  const invalidate = () =>
    queryClient.invalidateQueries({
      queryKey,
      exact: true,
    })

  return {query, invalidate}
}
