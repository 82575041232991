import {FlowbiteSizes, Modal} from 'flowbite-react'
import {FC, useReducer} from 'react'

type ClosedModal = {
  show: false
}

type OpenedModal<Payload> = {
  show: true
  payload: Payload
}

export type ModalState<Payload> = ClosedModal | OpenedModal<Payload>

type ContentProps<Payload> = {
  onClose: () => void
  payload: Payload
}

type ModalProps<Payload> = {
  state: ModalState<Payload>
  onClose: () => void
  content: FC<ContentProps<Payload>>
  size?: keyof Omit<FlowbiteSizes, 'xs'>
  className?: string
}

export const SimpleModal = <Payload,>({state, onClose, content: Content, size, className}: ModalProps<Payload>) => {
  return (
    <Modal show={state.show} onClose={onClose} size={size} className={className}>
      {state.show && <Content onClose={onClose} payload={state.payload} />}
    </Modal>
  )
}

type OpenAction<Payload> = {
  type: 'open'
  payload: Payload
}

type CloseAction = {
  type: 'close'
}

type Action<Payload> = OpenAction<Payload> | CloseAction

SimpleModal.useManager = <Payload,>() => {
  const reducer = (prev: ModalState<Payload>, action: Action<Payload>): ModalState<Payload> => {
    switch (action.type) {
      case 'open':
        return {
          show: true,
          payload: action.payload,
        }
      case 'close':
        return {
          show: false,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, {show: false})

  const open = (payload: Payload) => dispatch({type: 'open', payload})
  const close = () => dispatch({type: 'close'})

  return {
    state,
    open,
    close,
  }
}
