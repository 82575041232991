import {intersection} from 'lodash'
import {SessionOption} from '../commonTypes/entities'
import {FiltersState} from '../contexts/reportFilters'

export const filterSessionOptions = (
  sessionOptions: SessionOption[],
  {
    locations,
    categories,
    products,
    sessions,
  }: Pick<FiltersState, 'locations' | 'categories' | 'products'> & Partial<Pick<FiltersState, 'sessions'>>
) => {
  return sessionOptions.filter((option) => {
    if (!locations.includes(option.locationCode)) {
      return false
    }

    if (categories.length > 0 && intersection(categories, option.categoryCodes).length === 0) {
      return false
    }

    if (!products.includes(option.productCode)) {
      return false
    }

    if (sessions && !sessions.includes(option.value)) {
      return false
    }

    return true
  })
}
