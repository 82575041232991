import {intersection} from 'lodash'
import {ProductOption} from '../commonTypes/entities'
import {FiltersState} from '../contexts/reportFilters'

export const filterProductOptions = (
  productOptions: ProductOption[],
  {
    locations,
    categories,
    products,
  }: Pick<FiltersState, 'locations' | 'categories'> & Partial<Pick<FiltersState, 'products'>>
) => {
  return productOptions.filter((option) => {
    if (!locations.includes(option.locationCode)) {
      return false
    }

    if (categories.length > 0 && intersection(categories, option.categoryCodes).length === 0) {
      return false
    }

    if (products && !products.includes(option.value)) {
      return false
    }

    return true
  })
}
