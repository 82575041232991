import {User} from '@exp1/common-utils'
import axios from 'axios'
import {entities, params, responses} from 'commonTypes'

const axiosClient = () => {
  const instance = axios.create({
    baseURL: '/api',
    withCredentials: true,
  })

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if ([401, 403].includes(error.response.status)) {
        window.location.href = `https://sso.exp1.org/sign-in?redirect=${window.location.href}`
        return
      }

      throw error
    }
  )

  return instance
}

const getSoldoutDays = async (params: params.GetReport): Promise<responses.GetSoldoutDays> => {
  const result = await axiosClient().post<responses.GetSoldoutDays>('/soldoutDays', params)
  return result.data
}

const generateSoldoutDaysCsv = async (params: params.GetReport): Promise<responses.GenerateCsvReport> => {
  const result = await axiosClient().post<responses.GenerateCsvReport>('/soldoutDays/csv', params)
  return result.data
}

const getCapacityRate = async (params: params.GetReport): Promise<responses.GetCapacityRate> => {
  const result = await axiosClient().post<responses.GetCapacityRate>('/capacityRate', params)
  return result.data
}

const generateCapacityRateCsv = async (params: params.GetReport): Promise<responses.GenerateCsvReport> => {
  const result = await axiosClient().post<responses.GenerateCsvReport>('/capacityRate/csv', params)
  return result.data
}

const getProduct = async (productId: string): Promise<entities.Product> => {
  const result = await axiosClient().get<responses.GetProduct>('/products/' + productId)
  return result.data.product
}

const getUsers = async (): Promise<entities.BasicUserInfo[]> => {
  const result = await axiosClient().get<responses.GetUsers>('/users')
  return result.data.users
}

const iAm = async (): Promise<User> => {
  const result = await axiosClient().post<responses.IAm>('/iam')
  return result.data.user
}

const getFilterOptions = async (): Promise<responses.GetFilterOptions> => {
  const result = await axiosClient().post<responses.GetFilterOptions>('/filterOptions')
  return result.data
}

const getAvailabilityHistory = async (
  params: params.GetAvailabilityHistory
): Promise<responses.GetAvailabilityHistory> => {
  const result = await axiosClient().post<responses.GetAvailabilityHistory>('/availability/history', params)
  return result.data
}

const getAvailabilityPatches = async (): Promise<entities.AvailabilityPatchDetails[]> => {
  const result = await axiosClient().post<responses.GetAvailabilityPatches>('/availability/patches')
  return result.data.patches
}

const addAvailabilityPatch = async (params: params.AddAvailabilityPatch): Promise<responses.AddAvailabilityPatch> => {
  const result = await axiosClient().post<responses.AddAvailabilityPatch>('/availability/patch', params)
  return result.data
}

const addCapacityPatch = async (params: params.AddCapacityPatch): Promise<responses.AddCapacityPatch> => {
  const result = await axiosClient().post<responses.AddCapacityPatch>('/capacity/patch', params)
  return result.data
}

const getLocations = async (): Promise<responses.GetLocations> => {
  const result = await axiosClient().get<responses.GetLocations>('/locations')
  return result.data
}

const api = {
  getSoldoutDays,
  getCapacityRate,
  getProduct,
  getUsers,
  iAm,
  getFilterOptions,
  getAvailabilityHistory,
  addAvailabilityPatch,
  getAvailabilityPatches,
  addCapacityPatch,
  getLocations,
  generateCapacityRateCsv,
  generateSoldoutDaysCsv,
}

export default api
