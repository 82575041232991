import cx from 'classnames'
import {ReactNode} from 'react'

type Props = {
  label: string
  hint?: string
  textColorClass?: string
  value?: any
  unit?: string
  children?: ReactNode
}

export const TooltipItem = ({label, hint, textColorClass = 'text-black-600', value, unit = '', children}: Props) => {
  return (
    <>
      <div className={cx('flex flex-col', textColorClass)}>
        <span className="font-semibold text-[14px] leading-[15px] p-0">{label}</span>
        {hint && <span className="text-[8px] leading-[9px] p-0">{hint}</span>}
      </div>
      {children ? (
        children
      ) : (
        <span className={cx('text-[14px] leading-[15px] p-0 h-fit', textColorClass)}>
          {value === null || value === undefined ? '-' : `${value}${unit}`}
        </span>
      )}
    </>
  )
}
