import {entities} from 'commonTypes'

export const DISPLAY_FORMAT = 'DD-MMM-YY'
export const DISPLAY_CHART_FORMAT = 'DD MMM'
export const DISPLAY_WITH_DAY_FORMAT = 'DD-MMM-YY ddd'
export const INNER_FORMAT = 'YYYY-MM-DD'

export type BaseAddEntityParams = {}

export type BaseEditEntityParams<Id> = {
  id: Id
}

export type GetEntity<Entity, Id> = (id: Id) => Promise<Entity>
export type AddEntity<Entity, Params extends BaseAddEntityParams> = (params: Params) => Promise<Entity>
export type EditEntity<Entity, Id, Params extends BaseEditEntityParams<Id>> = (params: Params) => Promise<Entity>

export type AvailabilityPatchModalPayload = {
  snapshot: entities.AvailabilitySnapshot
  tourDate: string
  sampledTime: number
  sessionCode: string
}

export type CapacityPatchModalPayload = {
  snapshot: entities.CapacitySnapshot
  tourDate: string
  sampledTime: number
  tourReference: string
}
