import {uniq} from 'lodash'
import {useEffect, useState} from 'react'
import {entities} from '../../commonTypes'
import {useSessionOptions} from '../../contexts/app'
import {useReportFiltersContext} from '../../contexts/reportFilters'
import {formatChartDate, formatDate, formatSessionCode} from '../../helpers/formatters'
import {getColors} from '../../helpers/getColors'
import {getDateGroups} from '../../helpers/getDateGroups'
import {useContainer} from '../../hooks/useContainer'
import {useGetSoldoutDaysQuery} from '../../hooks/useGetSoldoutDaysQuery'
import {convertRecordsToSoldoutDateReports} from '../../utils/convertRecordsToSoldoutDateReports'
import {Tooltip, TooltipPosition} from '../Tooltip'
import {TooltipItem} from '../TooltipItem'

const d3 = require('d3')

type TooltipDetails = {
  soldoutDays: number | null | undefined
  tourDate: string
  sessionName: string
}

const DEFAULT_TOOLTIP_DETAILS: TooltipDetails = {
  tourDate: '-',
  sessionName: '-',
  soldoutDays: null,
}

const PADDING = 50
const ZERO = 0.03

const getSubgroups = (dateReports: entities.SoldoutDateReport[]) => {
  const subgroups = []

  for (const dateReport of dateReports) {
    for (const sessionReport of dateReport.sessionReports) {
      subgroups.push(sessionReport.sessionCode)
    }
  }

  return uniq(subgroups)
}

const getMaxSoldoutDays = (dateReports: entities.SoldoutDateReport[]) => {
  let max = 1

  for (const dateReport of dateReports) {
    for (const sessionReport of dateReport.sessionReports) {
      if (sessionReport.soldoutDays > max) {
        max = sessionReport.soldoutDays
      }
    }
  }

  return max
}

export const SoldoutDaysChart = () => {
  const {filters: reportFilters} = useReportFiltersContext()
  const [svg, svgRef] = useContainer()

  const getSoldoutQuery = useGetSoldoutDaysQuery(reportFilters)
  const sessionOptions = useSessionOptions(reportFilters)

  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition | null>(null)
  const [tooltipDetails, setTooltipDetails] = useState<TooltipDetails>(DEFAULT_TOOLTIP_DETAILS)

  useEffect(() => {
    if (svg === null) {
      return
    }

    if (!getSoldoutQuery.data) {
      return
    }

    svg.selectAll('*').remove()

    const dateReports = convertRecordsToSoldoutDateReports(getSoldoutQuery.data)
    const colors = getColors(sessionOptions)

    const groups = getDateGroups(reportFilters.from!, reportFilters.to!)
    const subgroups = getSubgroups(dateReports)
    const maxY = getMaxSoldoutDays(dateReports)

    const {clientWidth, clientHeight} = svg.node()

    const width = clientWidth - 2 * PADDING
    const height = clientHeight - 2 * PADDING

    const container = svg.append('g').attr('transform', `translate(${PADDING}, ${PADDING})`)

    const x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2])

    const y = d3.scaleLinear().domain([0, maxY]).range([height, 0])

    const xAxis = d3.axisBottom(x)
    const yAxis = d3.axisLeft(y).ticks(maxY)

    container.append('g').attr('transform', `translate(0, ${height})`).call(xAxis)

    container
      .append('text')
      .attr('transform', `translate(${width / 2}, ${height + (PADDING * 2) / 3})`)
      .style('font-weight', 'bold')
      .style('font-size', '12px')
      .style('fill', 'black')
      .style('text-anchor', 'middle')
      .text('Date')

    container.append('g').attr('transform', `translate(0, 0)`).call(yAxis)

    container
      .append('text')
      .attr('transform', `translate(${-PADDING / 2}, ${height / 2})  rotate(-90)`)
      .style('font-size', '12px')
      .style('font-weight', 'bold')
      .style('fill', 'black')
      .style('text-anchor', 'middle')
      .text('Soldout days')

    const xSubgroup = d3.scaleBand().domain(subgroups).range([0, x.bandwidth()]).padding([0.05])

    container
      .append('g')
      .selectAll('g')
      // Enter in data = loop group per group
      .data(dateReports)
      .enter()
      .append('g')
      .attr('transform', (d: any) => 'translate(' + x(formatChartDate(d.tourDate)) + ',0)')
      .selectAll('rect')
      .data((d: any) =>
        subgroups.map((sessionCode) => {
          const sessionReport = d.sessionReports.find((sessionReport: any) => sessionReport.sessionCode === sessionCode)
          return {
            sessionCode,
            soldoutDays: sessionReport ? sessionReport.soldoutDays : undefined,
            tourDate: d.tourDate,
          }
        })
      )
      .enter()
      .append('rect')
      .attr('x', (d: any) => xSubgroup(d.sessionCode))
      .attr('y', (d: any) => (d.soldoutDays === undefined || d.soldoutDays === 0 ? y(ZERO) : y(d.soldoutDays)))
      .attr('width', xSubgroup.bandwidth())
      .attr('height', (d: any) =>
        d.soldoutDays === undefined || d.soldoutDays === 0 ? height - y(ZERO) : height - y(d.soldoutDays)
      )
      .attr('fill', (d: any) => colors(d.sessionCode))
      .attr('fill-opacity', (d: any) => {
        return d.soldoutDays === undefined ? 0.25 : 1
      })
      .attr('stroke', 'gray')
      .on('mouseenter', (e: any) => {
        const {clientX, clientY, target} = e
        const {sessionCode, tourDate, soldoutDays} = target.__data__

        setTooltipPosition({
          left: clientX,
          top: clientY,
        })

        setTooltipDetails({
          sessionName: formatSessionCode(sessionCode),
          tourDate: formatDate(tourDate),
          soldoutDays,
        })
      })
      .on('mouseleave', () => setTooltipPosition(null))
  }, [svg, getSoldoutQuery.data, sessionOptions, reportFilters.from, reportFilters.to])

  return (
    <div className="relative">
      <svg ref={svgRef} className="w-full h-96" />
      <Tooltip visibility={!!tooltipPosition} position={tooltipPosition}>
        <TooltipItem label="Tour" value={tooltipDetails.sessionName} />
        <TooltipItem label="Date" value={tooltipDetails.tourDate} />
        <TooltipItem label="Soldout days" value={tooltipDetails.soldoutDays} />
      </Tooltip>
    </div>
  )
}
