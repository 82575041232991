import {groupBy, keys, orderBy} from 'lodash'
import {entities, responses} from '../commonTypes'

export const convertRecordsToSoldoutDateReports = (data: responses.GetSoldoutDays): entities.SoldoutDateReport[] => {
  const dateGroups = groupBy(data.records, 'tourDate')

  return orderBy(keys(dateGroups)).map((tourDate) => {
    const dateGroup = dateGroups[tourDate]

    return {
      tourDate,
      sessionReports: dateGroup,
    }
  })
}
