import {Components} from '@exp1/front-utils'
import cx from 'classnames'
import {useGenerateSoldoutDaysCsvMutation} from 'hooks/useGenerateSoldoutDaysCsvMutation'
import moment from 'moment'
import {MouseEvent, useState} from 'react'
import {entities} from '../../commonTypes'
import {useSessionOptions, useUser} from '../../contexts/app'
import {useReportFiltersContext} from '../../contexts/reportFilters'
import {
  formatAvailabilityDetails,
  formatDate,
  formatSampleTime,
  formatSessionCode,
  formatVacancies,
} from '../../helpers/formatters'
import {getColors} from '../../helpers/getColors'
import {useGetSoldoutDaysQuery} from '../../hooks/useGetSoldoutDaysQuery'
import {convertRecordsToSoldoutSessionReports} from '../../utils/convertRecordsToSoldoutSessionReports'
import {ColoredBoolean, ColoredStatus} from '../ColoredValue'
import {Tooltip, TooltipPosition} from '../Tooltip'
import {TooltipItem} from '../TooltipItem'
import {AvailabilityHistoryDialog} from '../modals/AvailabilityHistoryDialog'
import {AvailabilityPatchDialog} from '../modals/AvailabilityPatchDialog'

const SAMPLED_TIMES = [132, 108, 84, 60, 36, 12, 0, -1]

const DUMMY_AVAILABILITY_SNAPSHOT: entities.AvailabilitySnapshot = {
  original: null,
  patch: null,
  result: null,
  isSoldout: false,
}

const OLD_THRESHOLD = moment('2022-11-10')

type Props = {
  sampledTime: number
  availabilitySnapshot: entities.AvailabilitySnapshot | undefined
  onMouseEnter: (event: MouseEvent<HTMLSpanElement>) => void
  onMouseLeave: (event: MouseEvent<HTMLSpanElement>) => void
  onClick: (event: MouseEvent<HTMLSpanElement>) => void
  userIsAdmin: boolean
}

const SoldoutTd = ({userIsAdmin, sampledTime, availabilitySnapshot, onMouseLeave, onMouseEnter, onClick}: Props) => {
  if (!availabilitySnapshot) {
    return <td key={sampledTime} />
  }

  const {result, patch} = availabilitySnapshot

  if (!result) {
    return <td key={sampledTime} />
  }

  const vacancies = result.vacancies
  const closed = result.status === 'CLOSED'
  const opened = result.status === 'NOT_CLOSED'
  const freesale = vacancies === null
  const notScheduled = vacancies === undefined
  const soldout = !freesale && !notScheduled && vacancies <= 3
  const beforeStart = sampledTime > 0
  const afterStart = sampledTime <= 0

  return (
    <td
      className={cx('whitespace-nowrap text-sm', afterStart || closed ? 'text-gray-300' : 'text-gray-500')}
      key={sampledTime}
    >
      <div className={cx('px-0.5 py-0.5 w-min', {'border-amber-500 border': patch})}>
        <span
          className={cx('px-2.5 py-0.5', {
            'bg-red-100 text-red-800 text-xs rounded-full': beforeStart && opened && soldout,
            'cursor-pointer': beforeStart && userIsAdmin,
          })}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={beforeStart && userIsAdmin ? onClick : undefined}
        >
          {formatAvailabilityDetails(result)}
        </span>
      </div>
    </td>
  )
}

export const SoldoutDaysReport = () => {
  const user = useUser()
  const {filters: reportFilters} = useReportFiltersContext()
  const getSoldoutDaysQuery = useGetSoldoutDaysQuery(reportFilters)
  const generateSoldoutDaysCsv = useGenerateSoldoutDaysCsvMutation()

  const [availabilitySnapshot, setAvailabilitySnapshot] =
    useState<entities.AvailabilitySnapshot>(DUMMY_AVAILABILITY_SNAPSHOT)
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition | null>(null)

  const availabilityHistoryDialogManager = AvailabilityHistoryDialog.useManager()
  const availabilityPatchDialogManager = AvailabilityPatchDialog.useManager()

  const sessionOptions = useSessionOptions(reportFilters)
  const colors = getColors(sessionOptions)

  if (!getSoldoutDaysQuery.data) {
    return null
  }

  const sessionReports = convertRecordsToSoldoutSessionReports(getSoldoutDaysQuery.data)

  return (
    <div className="w-full p-3 flex flex-col gap-6">
      {sessionReports.map((sessionReport) => {
        return (
          <div key={sessionReport.sessionCode}>
            <div className="flex justify-between align-middle">
              <div className="flex gap-2 items-end">
                <div className="flex gap-2 items-center">
                  <div
                    className="rounded-full w-4 h-4 border border-gray-200"
                    style={{backgroundColor: colors(sessionReport.sessionCode)}}
                  />
                  <h1 className="text-xl font-semibold text-gray-900 leading-none">
                    {formatSessionCode(sessionReport.sessionCode)}
                  </h1>
                </div>
                <Components.StandardLink
                  onClick={() => generateSoldoutDaysCsv({...reportFilters, sessions: [sessionReport.sessionCode]})}
                  className="leading-none"
                >
                  Download csv
                </Components.StandardLink>
              </div>
              <div className="text-xs grid grid-cols-[20px_100px] gap-col-1">
                <span>∅</span>
                <span>not available</span>
                <span>×</span>
                <span>closed</span>
                <span>∞</span>
                <span>freesale</span>
              </div>
            </div>
            <table className="w-full divide-y divide-gray-300 table-fixed">
              <thead>
                <tr>
                  <th rowSpan={2} className="w-[120px] py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    Date
                  </th>
                  <th
                    colSpan={SAMPLED_TIMES.length}
                    className="py-3.5 px-3 text-center text-sm font-semibold text-gray-900"
                  >
                    Number of Vacancies at Sampled Time
                  </th>
                  <th rowSpan={2} className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                    Soldout Days
                  </th>
                  <th rowSpan={2} />
                </tr>
                <tr>
                  {SAMPLED_TIMES.map((sampledTime) => {
                    return (
                      <th
                        key={sampledTime}
                        className={cx('py-3.5 px-3 text-left text-sm font-semibold', {
                          'text-gray-900': sampledTime > 0,
                          'text-gray-300': sampledTime <= 0,
                        })}
                      >
                        {formatSampleTime(sampledTime)}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {sessionReport.dateReports.map((dateReport) => {
                  const momentTourDate = moment(dateReport.tourDate)
                  return (
                    <tr
                      key={dateReport.tourDate}
                      className={cx({'bg-[#fff8f8]': momentTourDate.isSameOrBefore(OLD_THRESHOLD)})}
                    >
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                        {formatDate(momentTourDate, true)}
                      </td>
                      {SAMPLED_TIMES.map((sampledTime) => {
                        const availabilitySnapshot = dateReport.sampledSnapshots[sampledTime]
                        const availabilityPatchId = {
                          productCode: dateReport.productCode,
                          availabilityId: dateReport.availabilityId,
                          sampledTime,
                        }

                        return (
                          <SoldoutTd
                            key={sampledTime}
                            userIsAdmin={'admin' in user.permissions}
                            availabilitySnapshot={availabilitySnapshot}
                            sampledTime={sampledTime}
                            onMouseEnter={(event) => {
                              if (!availabilitySnapshot) {
                                return
                              }
                              setAvailabilitySnapshot(availabilitySnapshot)
                              setTooltipPosition({left: event.clientX, top: event.clientY})
                            }}
                            onMouseLeave={() => setTooltipPosition(null)}
                            onClick={() =>
                              availabilityPatchDialogManager.edit(availabilityPatchId, {
                                snapshot: availabilitySnapshot,
                                tourDate: dateReport.tourDate,
                                sessionCode: dateReport.sessionCode,
                                sampledTime,
                              })
                            }
                          />
                        )
                      })}
                      <td className="whitespace-nowrap text-sm text-gray-500">
                        <span
                          className={cx('px-2.5 py-0.5', {
                            'bg-red-100 text-red-800 text-xs rounded-full': dateReport.soldoutDays > 0,
                          })}
                        >
                          {dateReport.soldoutDays}
                        </span>
                      </td>
                      <td className="text-sm">
                        <Components.StandardLink
                          onClick={() =>
                            availabilityHistoryDialogManager.open({
                              availabilityId: dateReport.availabilityId,
                              productId: dateReport.productId,
                              sessionCode: sessionReport.sessionCode,
                            })
                          }
                        >
                          Details
                        </Components.StandardLink>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )
      })}
      <Tooltip position={tooltipPosition} visibility={!!tooltipPosition}>
        <TooltipItem
          label="Vacancies"
          hint="From Ventrata API"
          value={formatVacancies(availabilitySnapshot?.result?.vacancies)}
        />
        <TooltipItem label="Capacity" hint="From Ventrata API" value={availabilitySnapshot?.result?.capacity} />
        <TooltipItem label="Guests" hint="From Ventrata API" value={availabilitySnapshot?.result?.guestQty} />

        <TooltipItem label="Available" hint="From Ventrata API">
          <ColoredBoolean value={availabilitySnapshot?.result?.available} />
        </TooltipItem>

        <TooltipItem label="Status" hint="From Ventrata API">
          <ColoredStatus value={availabilitySnapshot?.result?.status} />
        </TooltipItem>
      </Tooltip>
      <AvailabilityPatchDialog
        state={availabilityPatchDialogManager.state}
        onClose={availabilityPatchDialogManager.close}
        onSave={getSoldoutDaysQuery.refetch}
      />
      <AvailabilityHistoryDialog
        state={availabilityHistoryDialogManager.state}
        onClose={availabilityHistoryDialogManager.close}
      />
    </div>
  )
}
