import {Components} from '@exp1/front-utils'
import cx from 'classnames'
import {useGenerateCapacityRateCsvMutation} from 'hooks/useGenerateCapacityRateCsvMutation'
import {useSessionOptions} from '../../contexts/app'
import {useReportFiltersContext} from '../../contexts/reportFilters'
import {formatDate, formatNilable, formatSampleTime, formatSessionCode} from '../../helpers/formatters'
import {getColors} from '../../helpers/getColors'
import {useGetCapacityRateQuery} from '../../hooks/useGetCapacityRateQuery'
import {convertRecordsToCapacityRateSessionReports} from '../../utils/convertRecordsToCapacityRateSessionReports'
import {RateBadge} from '../RateBadge'
import {CapacityPatchDialog} from '../modals/CapacityPatchDialog'

export const CapacityRateReport = () => {
  const {filters: reportFilters} = useReportFiltersContext()
  const getCapacityRateQuery = useGetCapacityRateQuery(reportFilters)
  const generateCapacityRateCsvMutation = useGenerateCapacityRateCsvMutation()
  const capacityPatchDialogManager = CapacityPatchDialog.useManager()
  const sessionOptions = useSessionOptions(reportFilters)
  const colors = getColors(sessionOptions)

  if (!getCapacityRateQuery.data) {
    return null
  }

  const allRecords = getCapacityRateQuery.data.records
  const sessionReports = convertRecordsToCapacityRateSessionReports(allRecords)

  return (
    <div className="w-full p-3 flex flex-col gap-6">
      {sessionReports.map((sessionReport) => {
        return (
          <div key={sessionReport.sessionCode}>
            <div className="flex gap-2 items-end">
              <div className="flex gap-2 items-center">
                <div
                  className="rounded-full w-4 h-4 border border-gray-200"
                  style={{backgroundColor: colors(sessionReport.sessionCode)}}
                />
                <h1 className="text-xl font-semibold text-gray-900 leading-none">
                  {formatSessionCode(sessionReport.sessionCode)}
                </h1>
              </div>
              <Components.StandardLink
                onClick={() =>
                  generateCapacityRateCsvMutation({...reportFilters, sessions: [sessionReport.sessionCode]})
                }
                className="leading-none"
              >
                Download csv
              </Components.StandardLink>
            </div>
            <table className="w-full divide-y divide-gray-300 table-fixed">
              <thead>
                <tr>
                  <th className="w-[120px] py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Date</th>
                  <th className="py-3.5 px-3 text-left text-gray-900">
                    <div className="font-semibold text-[14px]">Sampled Time</div>
                    <div className="font-normal text-[10px] ">Last available</div>
                  </th>
                  <th className="py-3.5 px-3 text-left text-gray-900">
                    <div className="font-semibold text-[14px]">Number of Guests</div>
                    <div className="font-normal text-[10px] ">From Ventrata API</div>
                  </th>
                  <th className="py-3.5 px-3 text-left text-gray-900">
                    <div className="font-semibold text-[14px]">Number of Guides</div>
                    <div className="font-normal text-[10px] ">From guide calendar</div>
                  </th>
                  <th className="py-3.5 px-3 text-left text-gray-900">
                    <div className="font-semibold text-[14px]">Max Capacity</div>
                    <div className="font-normal text-[10px] ">guide capacity x guide number</div>
                  </th>
                  <th className="py-3.5 px-3 text-left text-gray-900">
                    <div className="font-semibold text-[14px]">Capacity Rate</div>
                    <div className="font-normal text-[10px] ">guest number / max capacity</div>
                  </th>
                  <th className="py-3.5 px-3 text-left text-gray-900" />
                </tr>
              </thead>
              <tbody>
                {sessionReport.dateReports.map((dateReport) => {
                  return (
                    <tr
                      key={dateReport.tourDate}
                      className={cx({'relative bg-gray-100': dateReport.ignore || dateReport.rate === null})}
                    >
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                        {dateReport.ignore && <div className="h-px bg-gray-500 w-full absolute left-0 top-[26px]" />}
                        {formatDate(dateReport.tourDate, true)}
                      </td>
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                        {formatSampleTime(dateReport.sampledTime)}
                      </td>
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                        {formatNilable(dateReport.snapshot.result.guestQty)}
                      </td>
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                        {formatNilable(dateReport.snapshot.result.guideQty)}
                      </td>
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                        {dateReport.capacity === null ? '-' : dateReport.snapshot.result.guideQty * dateReport.capacity}
                      </td>
                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                        <RateBadge rate={dateReport.rate} />
                      </td>

                      <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                        <Components.StandardLink
                          className="relative z-10"
                          onClick={() => {
                            const capacityPatchId = {
                              productCode: dateReport.productCode,
                              availabilityId: dateReport.availabilityId,
                              sampledTime: dateReport.sampledTime,
                            }

                            capacityPatchDialogManager.edit(capacityPatchId, {
                              snapshot: dateReport.snapshot,
                              tourDate: dateReport.tourDate,
                              tourReference: sessionReport.sessionCode,
                              sampledTime: dateReport.sampledTime,
                            })
                          }}
                        >
                          Edit
                        </Components.StandardLink>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )
      })}
      <CapacityPatchDialog
        state={capacityPatchDialogManager.state}
        onClose={capacityPatchDialogManager.close}
        onSave={getCapacityRateQuery.refetch}
      />
    </div>
  )
}
