import {omit} from 'lodash'
import moment from 'moment'
import {ReactNode, createContext, useCallback, useContext, useState} from 'react'
import {entities} from '../commonTypes'
import {INNER_FORMAT} from '../types'
import {useUser} from './app'

const FILTERS_VERSION = 3

type Props = {
  children: ReactNode
}

export type FiltersState = {
  from: entities.SingleFilterValue
  to: entities.SingleFilterValue
  products: entities.ListFilterValue
  categories: entities.ListFilterValue
  locations: entities.ListFilterValue
  sessions: entities.ListFilterValue
}

type ReportFiltersContextValue = {
  filters: FiltersState
  change: (newFiltersState: FiltersState) => void
}

export const ReportFiltersContext = createContext<ReportFiltersContextValue | null>(null)

export const useReportFiltersContext = (): ReportFiltersContextValue => {
  const context = useContext(ReportFiltersContext)

  if (!context) {
    throw new Error(`Report filters context is null`)
  }

  return context
}

const getSavedFilters = () => {
  const filtersJson = localStorage.getItem('filters')

  if (!filtersJson) {
    return null
  }

  try {
    const parsedFilters = JSON.parse(filtersJson)

    if (parsedFilters.version !== FILTERS_VERSION) {
      return null
    }

    return omit(parsedFilters, 'version') as FiltersState
  } catch (error) {
    console.error(error)
    return null
  }
}

const saveFilters = (filters: FiltersState) => {
  const payload = {
    ...filters,
    version: FILTERS_VERSION,
  }
  localStorage.setItem('filters', JSON.stringify(payload))
}

export const ReportFiltersProvider = ({children}: Props) => {
  const user = useUser()
  const savedFilters = getSavedFilters()

  const userPrimaryLocation = user.locations.length > 0 ? user.locations[0] : 'NYC'

  const defaultFilters = {
    from: moment().subtract(6, 'days').format(INNER_FORMAT),
    to: moment().format(INNER_FORMAT),
    categories: [],
    locations: [userPrimaryLocation],
    products: [],
    sessions: [],
  }

  if (!savedFilters) {
    saveFilters(defaultFilters)
  }

  const [filters, setFilters] = useState<FiltersState>(savedFilters || defaultFilters)

  const change = useCallback((newFiltersState: FiltersState) => {
    setFilters(newFiltersState)
    saveFilters(newFiltersState)
  }, [])

  const filterContextValue = {
    filters,
    change,
  }

  return <ReportFiltersContext.Provider value={filterContextValue}>{children}</ReportFiltersContext.Provider>
}
