import cx from 'classnames'

type Props = {
  type?: 'button' | 'submit' | 'reset'
  title: string
  onClick?: () => void
  appearance?: 'primary' | 'secondary' | 'white'
  className?: string
  disabled?: boolean
  color?: string
}

export const Button = ({
  appearance = 'primary',
  title,
  onClick,
  className,
  disabled,
  color = 'indigo',
  type = 'button',
}: Props) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={cx(
        `justify-center h-min inline-flex items-center px-4 py-2.5 border text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500 disabled:opacity-50`,
        {
          [`border-transparent shadow-sm text-white bg-${color}-600 hover:bg-${color}-700`]: appearance === 'primary',
          [`border-transparent text-${color}-700 bg-${color}-100 hover:bg-${color}-200`]: appearance === 'secondary',
          'border-gray-300 shadow-sm text-gray-700 bg-white hover:bg-gray-50': appearance === 'white',
        },
        className
      )}
      onClick={onClick}
    >
      {title}
    </button>
  )
}
