import {useState} from 'react'
import {useListSidebarFilter} from '../contexts/sidebarFilters'
import {CollapsableOptionFilter} from './CollapsableOptionFilter'
import { useLocationOptions } from '../contexts/app'

export const LocationFilter = () => {
  const locationFilter = useListSidebarFilter('locations')
  const [open, setOpen] = useState(false)

  const options = useLocationOptions()

  return (
    <CollapsableOptionFilter
      open={open}
      setOpen={setOpen}
      options={options}
      filterValue={locationFilter.value}
      changeFilter={locationFilter.change}
      title="Filter destination"
      filterKey="destinations"
    />
  )
}
