import {entities} from '../commonTypes'
import {AccordionItemProps} from './Accordion'
import {CollapseButton} from './CollapseButton'
import {OptionFilter} from './OptionFilter'
import { Option } from '../commonTypes/entities'

type Props = AccordionItemProps & {
  filterKey: string
  title: string
  filterValue: entities.ListFilterValue
  changeFilter: (value: entities.ListFilterValue) => void
  options: Option[]
}

export const CollapsableOptionFilter = ({
  filterKey,
  title,
  open,
  setOpen,
  filterValue,
  changeFilter,
  options,
}: Props) => {
  return (
    <div className="flex flex-col gap-3">
      <CollapseButton
        type="secondary"
        className="flex-1"
        title={title}
        onClick={() => setOpen(!open)}
        collapsed={!open}
      />
      {open && (
        <OptionFilter
          changeFilter={changeFilter}
          filterValue={filterValue}
          filterKey={filterKey}
          options={options}
        />
      )}
    </div>
  )
}
