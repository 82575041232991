import {get} from 'lodash'

type Props = {
  error: any
}

export const Failure = ({error}: Props) => {
  return (
    <div className="text-sm text-red-600">
      {get(error, 'response.data.error.message') || get(error, 'message') || 'Unknown error'}
    </div>
  )
}
