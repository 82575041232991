import {useState} from 'react'
import {useUser} from '../contexts/app'
import {CategoryFilter} from './CategoryFilter'
import {FilterAccordionItem} from './FilterAccordionItem'
import {LocationFilter} from './LocationFilter'
import {ProductFilter} from './ProductFilter'

export const TourFilters = () => {
  const [open, setOpen] = useState(true)
  const user = useUser()

  return (
    <FilterAccordionItem open={open} setOpen={setOpen} title="Filter tours">
      {'admin' in user.permissions && <LocationFilter />}
      <CategoryFilter />
      <ProductFilter />
    </FilterAccordionItem>
  )
}
