import React from 'react'
import api from '../../api'
import {entities} from '../../commonTypes'
import {formatNilable, formatSessionCode, formatVacancies} from '../../helpers/formatters'
import {CapacityPatchModalPayload} from '../../types'
import {EntityModal, ModalState, OpenedModalState} from '../EntityModal'
import {CheckboxField} from '../fields/CheckboxField'
import {InputField} from '../fields/InputField'

type ModalProps = {
  state: ModalState<CapacityPatchModalPayload, entities.CapacityPatchId>
  onClose: () => void
  onSave: () => void
}

type ContentProps = {
  state: OpenedModalState<CapacityPatchModalPayload, entities.CapacityPatchId>
}

export const CapacityPatchContent = ({state}: ContentProps) => {
  const {original, result} = state.payload.snapshot

  if (state.type !== 'edit') {
    throw new Error('Incorrect action')
  }

  const {availabilityId, sampledTime, productCode} = state.id

  return (
    <div className="grid grid-cols-4 text-sm gap-x-5 gap-y-1">
      <div></div>
      <div className="font-semibold">Original</div>
      <div className="font-semibold">Current</div>
      <div className="font-semibold">Patch</div>

      <input type="hidden" name="id.sampledTime" value={sampledTime} />
      <input type="hidden" name="id.productCode" value={productCode} />
      <input type="hidden" name="id.availabilityId" value={availabilityId} />

      <div className="self-center text-right font-semibold">Guest Number</div>
      <div className="self-center">{formatNilable(original?.guestQty)}</div>
      <div className="self-center">{formatNilable(result?.guestQty)}</div>
      <InputField name="patch.guestQty" type="number" min={0} max={1000} parse={parseInt} />

      <div className="self-center text-right font-semibold">Guide Number</div>
      <div className="self-center">{formatVacancies(original?.guideQty)}</div>
      <div className="self-center">{formatVacancies(result?.guideQty)}</div>
      <InputField name="patch.guideQty" type="number" min={0} max={1000} parse={parseInt} />

      <div className="self-center text-right font-semibold">Ignore</div>
      <CheckboxField name="patch.ignore" />
    </div>
  )
}

export const CapacityPatchDialog = ({state, onClose, onSave}: ModalProps) => {
  const initialValues = {
    patch: {
      guideQty: 0,
      guestQty: 0,
      ignore: false,
    },
  }

  return (
    <EntityModal
      state={state}
      onClose={onClose}
      onSave={onSave}
      formInitialValues={initialValues}
      getEntity={async () =>
        state.show && state.payload.snapshot.result ? {patch: state.payload.snapshot.result} : initialValues
      }
      editEntity={api.addCapacityPatch}
      content={CapacityPatchContent}
      entityName="availability patch"
      editTitle={(state) => {
        const {tourReference, tourDate, sampledTime} = state.payload
        return `Add patch for ${formatSessionCode(tourReference)}, ${tourDate}, ${sampledTime} hrs before`
      }}
    />
  )
}

CapacityPatchDialog.useManager = () => {
  return EntityModal.useManager<CapacityPatchModalPayload, entities.CapacityPatchId>()
}
