import {Route, Routes, useLocation} from 'react-router-dom'
import {FilterSidebar} from './components/FilterSidebar'
import {FiltersInfo} from './components/FiltersInfo'
import {Navbar} from './components/Navbar'
import {ReportFiltersProvider, useReportFiltersContext} from './contexts/reportFilters'
import {SidebarFiltersProvider} from './contexts/sidebarFilters'
import {useUser} from './contexts/app'
import {AvailabilityPatches} from './pages/AvailabilityPatches'
import {CapacityRate} from './pages/CapacityRate'
import {SoldoutDays} from './pages/SoldoutDays'
import {Summary} from './pages/Summary'

const Reports = () => {
  const filtersContext = useReportFiltersContext()

  return (
    <div className="min-h-[calc(100vh-65px)] flex h-[calc(100vh-65px)]">
      <SidebarFiltersProvider defaultFilters={filtersContext.filters}>
        <FilterSidebar />
      </SidebarFiltersProvider>
      <div className="w-full overflow-y-auto pb-6">
        <FiltersInfo />
        <Routes>
          <Route path="summary" element={<Summary />} />
          <Route path="soldoutDays" element={<SoldoutDays />} />
          <Route path="capacityRate" element={<CapacityRate />} />
        </Routes>
      </div>
    </div>
  )
}

export const Dashboard = () => {
  const location = useLocation()
  const user = useUser()

  if (user === null) {
    window.location.href = `https://sso.exp1.org/sign-in?redirect=${window.location.href}`
    return null
  }

  const tabs =
    'admin' in user.permissions
      ? [
          {name: 'Summary', link: '/app/summary'},
          {name: 'Soldout Days', link: '/app/soldoutDays'},
          {name: 'Capacity Rate', link: '/app/capacityRate'},
          {name: 'Products', link: 'https://ventrata-products.exp1.org/products'},
          {name: 'Availability patches', link: '/app/patches'},
        ]
      : [
          {name: 'Summary', link: '/app/summary'},
          {name: 'Soldout Days', link: '/app/soldoutDays'},
          {name: 'Capacity Rate', link: '/app/capacityRate'},
          {name: 'Products', link: '/app/products'},
        ]

  return (
    <div className="bg-white">
      <Navbar activeLink={location.pathname} tabs={tabs} />
      <ReportFiltersProvider>
        <Routes>
          <Route path="patches" element={<AvailabilityPatches />} />
          <Route path="*" element={<Reports />} />
        </Routes>
      </ReportFiltersProvider>
    </div>
  )
}
