import {Components} from '@exp1/front-utils'
import {useGenerateCapacityRateCsvMutation} from 'hooks/useGenerateCapacityRateCsvMutation'
import {round, sumBy} from 'lodash'
import {useReportFiltersContext} from '../../contexts/reportFilters'
import {useGetCapacityRateQuery} from '../../hooks/useGetCapacityRateQuery'
import {RateBadge} from '../RateBadge'

export const SummaryCapacityRateReport = () => {
  const {filters: reportFilters} = useReportFiltersContext()
  const getCapacityRateQuery = useGetCapacityRateQuery(reportFilters)
  const generateCapacityRateCsvMutation = useGenerateCapacityRateCsvMutation()

  if (!getCapacityRateQuery.data) {
    return null
  }

  const allCapacityRateRecords = getCapacityRateQuery.data.records.filter((record) => !record.ignore && record.rate)

  const totalCountedSessions = allCapacityRateRecords.length
  const totalIgnoredSessions = getCapacityRateQuery.data.records.length - allCapacityRateRecords.length
  const totalGuestNumber = sumBy(allCapacityRateRecords, 'guestQty')
  const totalGuideNumber = sumBy(allCapacityRateRecords, 'guideQty')
  const totalMaxCapacity = sumBy(allCapacityRateRecords, (report) => report.guideQty * report.capacity)
  const averageCapacityRate = totalMaxCapacity ? round((totalGuestNumber / totalMaxCapacity) * 100) : null

  return (
    <div className="w-full p-3 flex flex-col gap-6">
      <div className="flex gap-2 items-end">
        <h1 className="text-xl font-semibold text-gray-900 leading-none">Summary</h1>
        <Components.StandardLink
          onClick={() => generateCapacityRateCsvMutation(reportFilters)}
          className="leading-none"
        >
          Download csv
        </Components.StandardLink>
      </div>
      <table className="w-full divide-y divide-gray-300 table-fixed">
        <thead>
          <tr>
            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Total Counted Sessions</th>
            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Total Ignored Sessions</th>
            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Total Number of Guests</th>
            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Total Number of Guides</th>
            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Total Max Capacity</th>
            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Average Capacity Rate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{totalCountedSessions}</td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{totalIgnoredSessions}</td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{totalGuestNumber}</td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{totalGuideNumber}</td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{totalMaxCapacity}</td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
              <RateBadge rate={averageCapacityRate} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
