import {round, sumBy} from 'lodash'
import {useReportFiltersContext} from '../../contexts/reportFilters'
import {useGetCapacityRateQuery} from '../../hooks/useGetCapacityRateQuery'
import {useGetSoldoutDaysQuery} from '../../hooks/useGetSoldoutDaysQuery'
import {convertRecordsToSoldoutDateReports} from '../../utils/convertRecordsToSoldoutDateReports'
import {RateBadge} from '../RateBadge'

export const SummaryReport = () => {
  const {filters: reportFilters} = useReportFiltersContext()
  const getCapacityRateQuery = useGetCapacityRateQuery(reportFilters)
  const getSoldoutDaysQuery = useGetSoldoutDaysQuery(reportFilters)

  if (!getCapacityRateQuery.data || !getSoldoutDaysQuery.data) {
    return null
  }

  const allCapacityRateRecords = getCapacityRateQuery.data.records.filter((record) => {
    return record.rate !== null && !record.ignore
  })

  let soldoutSessions = 0
  let soldoutDays = 0
  let sessions = 0

  const soldoutDateReports = convertRecordsToSoldoutDateReports(getSoldoutDaysQuery.data)

  for (const dateReports of soldoutDateReports) {
    for (const sessionReport of dateReports.sessionReports) {
      sessions += 1
      if (sessionReport.soldoutDays) {
        soldoutSessions += 1
        soldoutDays += sessionReport.soldoutDays
      }
    }
  }

  const totalGuestNumber = sumBy(allCapacityRateRecords, 'guestQty')
  const totalMaxCapacity = sumBy(allCapacityRateRecords, ({guideQty, capacity}) => guideQty * capacity)
  const averageCapacityRate = totalMaxCapacity ? round((totalGuestNumber / totalMaxCapacity) * 100) : null

  return (
    <div className="w-full p-3 flex flex-col gap-6">
      <div>
        <h1 className="text-xl font-semibold text-gray-900">Summary Report</h1>
        <table className="w-full divide-y divide-gray-300 table-fixed">
          <thead>
            <tr>
              <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Total # of sessions</th>
              <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                # of sessions with at least 1 instance Sold Out
              </th>
              <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Total # of Sold Out days</th>
              <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Average Capacity Rate</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{sessions}</td>
              <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                {soldoutSessions} ({round((soldoutSessions * 100) / sessions)}%)
              </td>
              <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                {soldoutDays} ({round((soldoutDays * 100) / (sessions * 6))}%)
              </td>
              <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                <RateBadge rate={averageCapacityRate} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
