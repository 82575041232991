import { useSessionOptions } from '../contexts/app'
import {useListSidebarFilter} from '../contexts/sidebarFilters'
import {Divider} from './Divider'
import {OptionFilter} from './OptionFilter'

export const TourFilter = () => {
  const productFilter = useListSidebarFilter('products')
  const categoryFilter = useListSidebarFilter('categories')
  const locationFilter = useListSidebarFilter('locations')
  const sessionFilter = useListSidebarFilter('sessions')

  const options = useSessionOptions({
    products: productFilter.value,
    categories: categoryFilter.value,
    locations: locationFilter.value,
  })

  return (
    <div className="flex flex-col gap-3">
      <Divider />
      <h5 className="text-sm pl-3 font-semibold">Filtered tours</h5>
      <OptionFilter
        options={options}
        filterValue={sessionFilter.value}
        changeFilter={sessionFilter.change}
        filterKey="tours"
        withColors
      />
    </div>
  )
}
