import {CapacityRateChart} from '../components/charts/CapacityRateChart'
import {SoldoutDaysChart} from '../components/charts/SoldoutDaysChart'
import {SummaryReport} from '../components/reports/SummaryReport'

export const Summary = () => {
  return (
    <>
      <SummaryReport />
      <SoldoutDaysChart />
      <CapacityRateChart />
    </>
  )
}
