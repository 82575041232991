import {map} from 'lodash'
import {ReactNode, createContext, useCallback, useContext, useReducer} from 'react'
import {entities} from '../commonTypes'
import {filterProductOptions} from '../utils/filterProductOptions'
import {filterSessionOptions} from '../utils/filterSessionOptions'
import {useFilterOptions} from './app'
import {FiltersState} from './reportFilters'

type Props = {
  children: ReactNode
  defaultFilters: FiltersState
}

type SingleFilterName = 'from' | 'to'
type ListFilterName = 'locations' | 'categories' | 'products' | 'sessions'

type SingleFilterAction = {
  name: SingleFilterName
  value: entities.SingleFilterValue
}

type ListFilterAction = {
  name: ListFilterName
  value: entities.ListFilterValue
}

type Action = SingleFilterAction | ListFilterAction

type SidebarFiltersContextValue = {
  filters: FiltersState
  change: (action: Action) => void
}

export const SidebarFiltersContext = createContext<SidebarFiltersContextValue | null>(null)

export const useSidebarFiltersContext = (): SidebarFiltersContextValue => {
  const context = useContext(SidebarFiltersContext)

  if (!context) {
    throw new Error(`Sidebar filters context is null`)
  }

  return context
}

export const useListSidebarFilter = (name: ListFilterName) => {
  const context = useSidebarFiltersContext()

  const value = context.filters[name]

  if (value !== undefined && !Array.isArray(value)) {
    throw new Error(`Filter '${name}' is not a list sidebar filter`)
  }

  const change = (value: entities.ListFilterValue) => context.change({name, value})
  return {value, change}
}

export const useSingleSidebarFilter = (name: SingleFilterName) => {
  const context = useSidebarFiltersContext()

  const value = context.filters[name]

  if (value !== undefined && typeof value !== 'string') {
    throw new Error(`Filter '${name}' is not a single sidebar filter`)
  }

  const change = (value: entities.SingleFilterValue) => context.change({name, value})
  return {value, change}
}

export const SidebarFiltersProvider = ({children, defaultFilters}: Props) => {
  const {sessionOptions, productOptions} = useFilterOptions()

  const reducer = (prevState: FiltersState, action: Action): FiltersState => {
    switch (action.name) {
      case 'from':
      case 'to':
        return {
          ...prevState,
          [action.name]: action.value,
        }
      case 'sessions':
        return {
          ...prevState,
          sessions: action.value,
        }
      case 'products':
        return {
          ...prevState,
          products: action.value,
          sessions: map(filterSessionOptions(sessionOptions, {...prevState, products: action.value}), 'value'),
        }
      case 'locations':
        return {
          ...prevState,
          locations: action.value,
          products: map(filterProductOptions(productOptions, {...prevState, locations: action.value}), 'value'),
          sessions: map(filterSessionOptions(sessionOptions, {...prevState, locations: action.value}), 'value'),
        }
      case 'categories':
        return {
          ...prevState,
          categories: action.value,
          products: map(filterProductOptions(productOptions, {...prevState, categories: action.value}), 'value'),
          sessions: map(filterSessionOptions(sessionOptions, {...prevState, categories: action.value}), 'value'),
        }
    }
  }

  const [filters, dispatch] = useReducer(reducer, defaultFilters)

  const change = useCallback((action: Action) => {
    dispatch(action)
  }, [])

  const filterContextValue = {
    filters,
    change,
  }

  return <SidebarFiltersContext.Provider value={filterContextValue}>{children}</SidebarFiltersContext.Provider>
}
