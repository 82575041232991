import moment from 'moment'
import {useState} from 'react'
import {useSingleSidebarFilter} from '../contexts/sidebarFilters'
import {INNER_FORMAT} from '../types'
import {Button} from './Button'
import {FilterAccordionItem} from './FilterAccordionItem'
import {Input} from './Input'

export const DateFilters = () => {
  const [open, setOpen] = useState(true)
  const fromFilter = useSingleSidebarFilter('from')
  const toFilter = useSingleSidebarFilter('to')

  const onLast7DaysClick = () => {
    fromFilter.change(moment().subtract(6, 'days').format(INNER_FORMAT))
    toFilter.change(moment().format(INNER_FORMAT))
  }

  const onThisMonthClick = () => {
    fromFilter.change(moment().startOf('month').format(INNER_FORMAT))
    toFilter.change(moment().endOf('month').format(INNER_FORMAT))
  }

  const onThisYearClick = () => {
    fromFilter.change(moment().startOf('year').format(INNER_FORMAT))
    toFilter.change(moment().endOf('year').format(INNER_FORMAT))
  }

  return (
    <FilterAccordionItem open={open} setOpen={setOpen} title="Filter date range">
      <div className="flex gap-2">
        <Button
          appearance="secondary"
          className="flex-1 !px-0 text-nowrap"
          title="Last 7 days"
          onClick={onLast7DaysClick}
        />
        <Button
          appearance="secondary"
          className="flex-1 !px-0 text-nowrap"
          title="This month"
          onClick={onThisMonthClick}
        />
        <Button
          appearance="secondary"
          className="flex-1 !px-0 text-nowrap"
          title="This year"
          onClick={onThisYearClick}
        />
      </div>
      <div className="flex gap-2">
        <Input
          label="From"
          type="date"
          name="from"
          id="from"
          value={fromFilter.value}
          className="flex-1"
          onChange={fromFilter.change}
        />
        <Input
          label="To"
          type="date"
          name="to"
          id="to"
          value={toFilter.value}
          className="flex-1"
          onChange={toFilter.change}
        />
      </div>
    </FilterAccordionItem>
  )
}
