import {groupBy, keys, orderBy} from 'lodash'
import {entities} from '../commonTypes'

export const convertRecordsToCapacityRateSessionReports = (
  records: entities.SessionSampledCapacity[]
): entities.CapacitySessionReport[] => {
  const sessionGroups = groupBy(records, 'sessionCode')

  return orderBy(keys(sessionGroups)).map((sessionCode) => {
    const sessionGroup = sessionGroups[sessionCode]

    return {
      sessionCode,
      dateReports: sessionGroup,
    }
  })
}
