import React from 'react'
import {Field} from 'react-final-form'

type Props = {
  name: string
  label?: string
}

export const CheckboxField = ({label, name}: Props) => {
  return (
    <Field name={name} type="checkbox">
      {({input, meta}) => {
        return (
          <div className="flex flex-col gap-1">
            {label && (
              <label htmlFor="capacity" className="block text-sm font-medium text-gray-700">
                {label}
              </label>
            )}
            <input
              {...input}
              type="checkbox"
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            {meta.touched && meta.error && <span>{meta.error}</span>}
          </div>
        )
      }}
    </Field>
  )
}
