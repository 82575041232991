import {Select} from 'flowbite-react'
import React from 'react'
import {Field} from 'react-final-form'
import {entities} from '../../commonTypes'

type Props = {
  name: string
  label?: string
  options: entities.Option[]
  parse?: (value: string) => any
}

export const SelectField = ({label, name, options, parse}: Props) => {
  return (
    <Field name={name} type="select" parse={parse}>
      {({input, meta}) => (
        <div className="flex flex-col gap-1">
          {label && (
            <label htmlFor="capacity" className="block text-sm font-medium text-gray-700">
              {label}
            </label>
          )}
          <Select {...input} sizing="sm">
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          {meta.touched && meta.error && <span>{meta.error}</span>}
        </div>
      )}
    </Field>
  )
}
