import cx from 'classnames'
import {Link} from 'react-router-dom'
import {useUser} from '../contexts/app'
import logo from '../logo.png'

type Tab = {
  name: string
  link: string
}

type Props = {
  tabs: Tab[]
  activeLink: string
}

export const Navbar = ({tabs, activeLink}: Props) => {
  const user = useUser()
  const logout = () => {
    window.location.href = 'https://sso.exp1.org/sign-out'
  }

  return (
    <nav className="bg-white border-b sticky top-0 z-10">
      <div className="max-w-7xl mx-auto px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <img className="block h-8 w-auto" src={logo} alt="Exp1" />
            </div>
            <div className="ml-6 flex space-x-8">
              {tabs.map((tab) => {
                return tab.link.startsWith('http') ? (
                  <a
                    key={tab.link}
                    href={tab.link}
                    target="_blank"
                    rel="noreferrer"
                    className={cx('inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium', {
                      'border-indigo-500 text-gray-900 cursor-auto': tab.link === activeLink,
                      'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700':
                        tab.link !== activeLink,
                    })}
                  >
                    {tab.name}
                  </a>
                ) : (
                  <Link
                    key={tab.link}
                    to={tab.link}
                    className={cx('inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium', {
                      'border-indigo-500 text-gray-900 cursor-auto': tab.link === activeLink,
                      'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700':
                        tab.link !== activeLink,
                    })}
                  >
                    {tab.name}
                  </Link>
                )
              })}
            </div>
          </div>
          <div className="flex">
            <div className="ml-6 flex space-x-8">
              <div className="inline-flex items-center px-1 pt-1 text-sm font-medium">{user.name}</div>
              <div
                onClick={() => logout()}
                className="cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Logout
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
