import {Components} from '@exp1/front-utils'
import {useGenerateSoldoutDaysCsvMutation} from 'hooks/useGenerateSoldoutDaysCsvMutation'
import {round} from 'lodash'
import {useReportFiltersContext} from '../../contexts/reportFilters'
import {useGetSoldoutDaysQuery} from '../../hooks/useGetSoldoutDaysQuery'
import {convertRecordsToSoldoutDateReports} from '../../utils/convertRecordsToSoldoutDateReports'

export const SummarySoldoutDaysReport = () => {
  const {filters: reportFilters} = useReportFiltersContext()
  const getSoldoutDaysQuery = useGetSoldoutDaysQuery(reportFilters)
  const generateSoldoutDaysCsv = useGenerateSoldoutDaysCsvMutation()

  if (!getSoldoutDaysQuery.data) {
    return null
  }

  let soldoutSessions = 0
  let soldoutDays = 0
  let sessions = 0

  const dateReports = convertRecordsToSoldoutDateReports(getSoldoutDaysQuery.data)

  for (const dateReport of dateReports) {
    for (const tourReport of dateReport.sessionReports) {
      sessions += 1

      if (tourReport.soldoutDays) {
        soldoutSessions += 1
        soldoutDays += tourReport.soldoutDays
      }
    }
  }

  const soldoutSessionsPercent = sessions === 0 ? '' : `(${round((soldoutSessions * 100) / sessions)}%)`
  const soldoutDaysPercent = sessions === 0 ? '' : `(${round((soldoutDays * 100) / (sessions * 6))}%)`

  return (
    <div className="w-full p-3 flex flex-col gap-6">
      <div className="flex gap-2 items-end">
        <h1 className="text-xl font-semibold text-gray-900 leading-none">Summary</h1>
        <Components.StandardLink onClick={() => generateSoldoutDaysCsv(reportFilters)} className="leading-none">
          Download csv
        </Components.StandardLink>
      </div>
      <table className="w-full divide-y divide-gray-300 table-fixed">
        <thead>
          <tr>
            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Total # of sessions</th>
            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
              # of sessions with at least 1 instance Sold Out
            </th>
            <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Total # of Sold Out days</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{sessions}</td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
              {soldoutSessions} {soldoutSessionsPercent}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
              {soldoutDays} {soldoutDaysPercent}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
