import {useMutation} from 'react-query'
import {AddEntity, BaseAddEntityParams, BaseEditEntityParams, EditEntity} from '../types'

export const useAddOrChangeEntityMutation = <
  Entity,
  Id,
  AddParams extends BaseAddEntityParams,
  EditParams extends BaseEditEntityParams<Id>
>(
  addEntity: AddEntity<Entity, AddParams>,
  editEntity: EditEntity<Entity, Id, EditParams>
) => {
  return useMutation(async (params: AddParams | EditParams) => {
    return 'id' in params ? editEntity(params as EditParams) : addEntity(params as AddParams)
  })
}
