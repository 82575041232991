import api from 'api'
import {useMutation} from 'react-query'
import {saveData} from 'utils/saveData'
import {params} from '../commonTypes'

export const useGenerateCapacityRateCsvMutation = () => {
  const generate = useMutation(async (params: params.GetReport) => api.generateCapacityRateCsv(params), {
    onSuccess({csv, filename}) {
      saveData(csv, filename)
    },
  })

  return generate.mutate
}
