import {useState} from 'react'
import {useListSidebarFilter} from '../contexts/sidebarFilters'
import {CollapsableOptionFilter} from './CollapsableOptionFilter'
import { useProductOptions } from '../contexts/app'

export const ProductFilter = () => {
  const productFilter = useListSidebarFilter('products')
  const categoryFilter = useListSidebarFilter('categories')
  const locationFilter = useListSidebarFilter('locations')
  const [open, setOpen] = useState(false)

  const options = useProductOptions({
    categories: categoryFilter.value,
    locations: locationFilter.value,
  })

  return (
    <CollapsableOptionFilter
      open={open}
      setOpen={setOpen}
      options={options}
      filterValue={productFilter.value}
      changeFilter={productFilter.change}
      title="Filter product"
      filterKey="products"
    />
  )
}
