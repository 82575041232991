export const saveData = (function () {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  return function (csv: string, filename: string) {
    const blob = new Blob([csv], {type: 'octet/stream'})
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    a.click()
    window.URL.revokeObjectURL(url)
  }
})()
