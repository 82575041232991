import {groupBy, keys, orderBy} from 'lodash'
import {entities, responses} from '../commonTypes'

export const convertRecordsToSoldoutSessionReports = (
  data: responses.GetSoldoutDays
): entities.SoldoutSessionReport[] => {
  const sessionGroups = groupBy(data.records, 'sessionCode')

  return orderBy(keys(sessionGroups)).map((sessionCode) => {
    const sessionGroup = sessionGroups[sessionCode]

    return {
      sessionCode,
      dateReports: sessionGroup,
    }
  })
}
