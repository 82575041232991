type Props = {
  label: string
  value?: string
  name: string
  type: string
  id?: string
  className?: string
  onChange?: (value: string) => void
}

export const Input = ({id, label, value, type, name, className, onChange}: Props) => {
  return (
    <div className={className}>
      <label htmlFor="from" className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <input
          type={type}
          name={name}
          id={id}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md"
          value={value}
          onChange={onChange ? (event) => onChange(event.target.value) : undefined}
        />
      </div>
    </div>
  )
}
