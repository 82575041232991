import {isEqual} from 'lodash'
import {useReportFiltersContext} from '../contexts/reportFilters'
import {useSidebarFiltersContext} from '../contexts/sidebarFilters'
import {Button} from './Button'
import {DateFilters} from './DateFilters'
import {TourFilter} from './SessionFilter'
import {TourFilters} from './TourFilters'

export const FilterSidebar = () => {
  const reportFiltersContext = useReportFiltersContext()
  const sidebarFiltersContext = useSidebarFiltersContext()

  return (
    <div className="flex flex-col min-w-[360px] overflow-y-auto">
      <div className="flex flex-col flex-grow border-r border-gray-200 pb-4">
        <div className="flex-grow flex flex-col">
          <div className="flex flex-1 flex-col px-2" aria-label="Sidebar">
            <div className="sticky top-0 z-10 bg-white w-full py-3">
              <Button
                color="lime"
                title="Apply changes"
                onClick={() => reportFiltersContext.change(sidebarFiltersContext.filters)}
                className="w-full"
                disabled={isEqual(sidebarFiltersContext.filters, reportFiltersContext.filters)}
              />
            </div>
            <div className="flex flex-1 flex-col gap-3 py-3">
              <DateFilters />
              <TourFilters />
              <TourFilter />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
