import moment from 'moment'
import {DISPLAY_CHART_FORMAT} from '../types'

export const getDateGroups = (from: string, to: string) => {
  const toDate = moment(to).format(DISPLAY_CHART_FORMAT)
  const result = []
  const date = moment(from)

  while (true) {
    const formattedDate = date.format(DISPLAY_CHART_FORMAT)
    result.push(formattedDate)

    if (formattedDate === toDate) {
      return result
    }

    date.add(1, 'day')
  }
}
