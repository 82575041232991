import api from '../../api'
import {entities} from '../../commonTypes'
import {formatNilable, formatSessionCode, formatVacancies} from '../../helpers/formatters'
import {AvailabilityPatchModalPayload} from '../../types'
import {ColoredBoolean, ColoredStatus} from '../ColoredValue'
import {EntityModal, ModalState, OpenedModalState} from '../EntityModal'
import {InputField} from '../fields/InputField'
import {SelectField} from '../fields/SelectField'

type ModalProps = {
  state: ModalState<AvailabilityPatchModalPayload, entities.AvailabilityPatchId>
  onClose: () => void
  onSave: () => void
}

type ContentProps = {
  state: OpenedModalState<AvailabilityPatchModalPayload, entities.AvailabilityPatchId>
}

export const AvailabilityPatchContent = ({state}: ContentProps) => {
  const statusOptions = [
    {value: 'CLOSED', label: 'Closed'},
    {value: 'NOT_CLOSED', label: 'Not closed'},
  ]

  const availableOptions = [
    {value: 'true', label: 'Yes'},
    {value: 'false', label: 'No'},
  ]

  const {original, result} = state.payload.snapshot

  if (state.type !== 'edit') {
    throw new Error('Incorrect action')
  }

  const {availabilityId, sampledTime, productCode} = state.id

  return (
    <div className="grid grid-cols-4 text-sm gap-x-5 gap-y-1">
      <div></div>
      <div className="font-semibold">Original</div>
      <div className="font-semibold">Current</div>
      <div className="font-semibold">Patch</div>

      <input type="hidden" name="id.sampledTime" value={sampledTime} />
      <input type="hidden" name="id.productCode" value={productCode} />
      <input type="hidden" name="id.availabilityId" value={availabilityId} />

      <div className="self-center text-right font-semibold">Capacity</div>
      <div className="self-center">{formatNilable(original?.capacity)}</div>
      <div className="self-center">{formatNilable(result?.capacity)}</div>
      <InputField name="patch.capacity" type="number" min={0} max={1000} parse={parseInt} />

      <div className="self-center text-right font-semibold">Vacancies</div>
      <div className="self-center">{formatVacancies(original?.vacancies)}</div>
      <div className="self-center">{formatVacancies(result?.vacancies)}</div>
      <InputField name="patch.vacancies" type="number" min={0} max={1000} parse={parseInt} />

      <div className="self-center text-right font-semibold">Guests</div>
      <div className="self-center">{formatNilable(original?.guestQty)}</div>
      <div className="self-center">{formatNilable(result?.guestQty)}</div>
      <InputField name="patch.guestQty" type="number" min={0} max={1000} parse={parseInt} />

      <div className="self-center text-right font-semibold">Availability</div>
      <div className="self-center">
        <ColoredBoolean value={original?.available} />
      </div>
      <div className="self-center">
        <ColoredBoolean value={result?.available} />
      </div>
      <SelectField name="patch.available" options={availableOptions} parse={Boolean} />

      <div className="self-center text-right font-semibold">Status</div>
      <div className="self-center">
        <ColoredStatus value={original?.status} />
      </div>
      <div className="self-center">
        <ColoredStatus value={result?.status} />
      </div>
      <SelectField name="patch.status" options={statusOptions} />
    </div>
  )
}

export const AvailabilityPatchDialog = ({state, onClose, onSave}: ModalProps) => {
  const initialValues = {
    patch: {
      capacity: 0,
      vacancies: 0,
      guestQty: 0,
      available: false,
      status: 'CLOSED',
    },
  }

  return (
    <EntityModal
      state={state}
      onClose={onClose}
      onSave={onSave}
      formInitialValues={initialValues}
      getEntity={async () =>
        state.show && state.payload.snapshot.result ? {patch: state.payload.snapshot.result} : initialValues
      }
      editEntity={api.addAvailabilityPatch}
      content={AvailabilityPatchContent}
      entityName="availability patch"
      editTitle={(state) => {
        const {sessionCode, tourDate, sampledTime} = state.payload
        return `Add patch for ${formatSessionCode(sessionCode)}, ${tourDate}, ${sampledTime} hrs before`
      }}
    />
  )
}

AvailabilityPatchDialog.useManager = () => {
  return EntityModal.useManager<AvailabilityPatchModalPayload, entities.AvailabilityPatchId>()
}
