import React, {ReactNode} from 'react'

type Props = {
  children: ReactNode
  color: string
}

export const Badge = ({children, color}: Props) => {
  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-${color}-100 text-${color}-800`}
    >
      {children}
    </span>
  )
}
