import {formatBoolean, formatStatus} from '../helpers/formatters'

type ColoredValueProps = {
  value: any
  colorMap: Map<any, string>
}

export const ColoredValue = ({value, colorMap}: ColoredValueProps) => {
  return <div className={colorMap.get(value)}>{value}</div>
}

type ColoredBooleanProps = {
  value: boolean | null | undefined
}

export const ColoredBoolean = ({value}: ColoredBooleanProps) => {
  const colorMap = new Map([
    ['Yes', 'text-green-400'],
    ['No', 'text-red-400'],
    ['-', 'text-black-400'],
  ])

  return <ColoredValue colorMap={colorMap} value={formatBoolean(value)} />
}

type ColoredStatusProps = {
  value: 'CLOSED' | 'NOT_CLOSED' | null | undefined
}

export const ColoredStatus = ({value}: ColoredStatusProps) => {
  const colorMap = new Map([
    ['Closed', 'text-red-400'],
    ['Not closed', 'text-green-400'],
    ['-', 'text-black-400'],
  ])

  return <ColoredValue colorMap={colorMap} value={formatStatus(value)} />
}
