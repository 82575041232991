import {Modal} from 'flowbite-react'
import moment from 'moment'
import 'moment-timezone'
import React from 'react'
import {formatDate, formatSessionCode} from '../../helpers/formatters'
import {useGetAvailabilityHistoryQuery} from '../../hooks/useGetAvailabilityHistoryQuery'
import {useGetProductQuery} from '../../hooks/useGetProductQuery'
import {ModalState, SimpleModal} from '../SimpleModal'

export type AvailabilityHistoryPayload = {
  sessionCode: string
  productId: string
  availabilityId: string
}

type ModalProps = {
  state: ModalState<AvailabilityHistoryPayload>
  onClose: () => void
}

type ContentProps = {
  payload: AvailabilityHistoryPayload
}

export const AvailabilityHistoryContent = ({payload}: ContentProps) => {
  const {sessionCode, productId, availabilityId} = payload
  const getAvailabilityHistoryQuery = useGetAvailabilityHistoryQuery({productId, availabilityId})
  const getProductQuery = useGetProductQuery(productId)

  return (
    <div>
      <Modal.Header>
        Availability history of {formatSessionCode(sessionCode)}, {formatDate(availabilityId)}
      </Modal.Header>
      <Modal.Body>
        <div className="h-[calc(100vh-160px)] overflow-y-auto">
          <table className="w-full table-fixed border-collapse">
            <thead className="bg-white py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sticky top-0">
              <tr>
                <th rowSpan={2} className="w-[140px]">
                  Date
                </th>
                <th rowSpan={2} className="w-60">
                  Option
                  <br />
                  name
                </th>
                <th rowSpan={2} className="w-[140px]">
                  Option
                  <br />
                  status
                </th>
                <th rowSpan={2} className="w-[140px]">
                  Source
                </th>
                <th colSpan={3}>
                  <div className="text-center bg-gray-100 mr-px mb-px">Option</div>
                </th>
                <th colSpan={3}>
                  <div className="text-center bg-gray-100 mb-px">Aggregated</div>
                </th>
              </tr>
              <tr className="text-center">
                <th title="Capacity">
                  <div className="bg-gray-100 mb-px mr-px">C</div>
                </th>
                <th title="Vacancies">
                  <div className="bg-gray-100 mb-px mr-px">V</div>
                </th>
                <th title="Guests">
                  <div className="bg-gray-100 mb-px mr-px">G</div>
                </th>
                <th title="Capacity">
                  <div className="bg-gray-100 mb-px mr-px">C</div>
                </th>
                <th title="Vacancies">
                  <div className="bg-gray-100 mb-px mr-px">V</div>
                </th>
                <th title="Guests">
                  <div className="bg-gray-100 mb-px">G</div>
                </th>
              </tr>
              <tr>
                <td colSpan={10} className="h-px bg-gray-300" />
              </tr>
            </thead>
            <tbody className="whitespace-nowrap text-left py-4 px-3 text-sm text-gray-500">
              {getAvailabilityHistoryQuery.data &&
                getProductQuery.data &&
                getAvailabilityHistoryQuery.data.history.map((item, i) => {
                  const product = getProductQuery.data
                  const timeZone = product.timeZone

                  const prevItem = i > 0 ? getAvailabilityHistoryQuery.data.history[i - 1] : null
                  const createdAt = moment.tz(item.createdAt, timeZone)
                  const prevCreatedAt = prevItem ? moment.tz(prevItem.createdAt, timeZone) : null
                  const diff = prevCreatedAt ? createdAt.diff(prevCreatedAt) : null

                  return (
                    <React.Fragment key={item.createdAt}>
                      {diff && diff.valueOf() > 1000 && (
                        <tr className="h-7 text-xs text-center text-gray-400">
                          <td>{moment.duration(diff, 'ms').humanize()}</td>
                        </tr>
                      )}
                      <tr>
                        <td>{createdAt.format('DD-MMM-YY, HH:mm:ss')}</td>
                        <td>{item.optionReference}</td>
                        <td>{item.availability.status}</td>
                        <td>{item.source}</td>
                        <td className="text-center">{item.availability.capacity}</td>
                        <td className="text-center">{item.availability.vacancies}</td>
                        <td className="text-center">{item.availability.guestQty}</td>
                        <td className="text-center">{item.aggregatedAvailability.capacity}</td>
                        <td className="text-center">{item.aggregatedAvailability.vacancies}</td>
                        <td className="text-center">{item.aggregatedAvailability.guestQty}</td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              {getAvailabilityHistoryQuery.data && getAvailabilityHistoryQuery.data.history.length === 0 && (
                <tr>
                  <td colSpan={9} className="text-center py-3">
                    No items
                  </td>
                </tr>
              )}
              {getAvailabilityHistoryQuery.isLoading && (
                <tr>
                  <td colSpan={9} className="text-center py-3">
                    Loading...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </div>
  )
}

export const AvailabilityHistoryDialog = ({state, onClose}: ModalProps) => {
  return <SimpleModal size="5xl" state={state} onClose={onClose} content={AvailabilityHistoryContent} />
}

AvailabilityHistoryDialog.useManager = () => {
  return SimpleModal.useManager<AvailabilityHistoryPayload>()
}
