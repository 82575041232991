import moment from 'moment'
import 'moment-timezone'
import {Badge} from '../components/Badge'
import {ColoredBoolean, ColoredStatus} from '../components/ColoredValue'
import {Failure} from '../components/Failure'
import {usePrimaryLocation} from '../contexts/app'
import {formatDate, formatNilable, formatSessionCode, formatTimestamp} from '../helpers/formatters'
import {useGetAvailabilityPatchesQuery} from '../hooks/useGetAvailabilityPatchesQuery'
import {useGetUsersQuery} from '../hooks/useGetUsers'

export const AvailabilityPatches = () => {
  const getAvailabilityPatchesQuery = useGetAvailabilityPatchesQuery()
  const getUsersQuery = useGetUsersQuery()
  const primaryLocation = usePrimaryLocation()
  const timeZone = primaryLocation ? primaryLocation.timeZone : 'UTC'

  const loading = getAvailabilityPatchesQuery.isLoading || getUsersQuery.isLoading
  const error = getAvailabilityPatchesQuery.error || getUsersQuery.error

  return (
    <>
      {loading && (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center position-absolute top-0 left-0 fade show">
          <div className="spinner-border text-info" role="status" />
        </div>
      )}
      <div className="max-w-7xl mx-auto px-8 mt-3">
        {error ? <Failure error={error} /> : null}
        {getAvailabilityPatchesQuery.data && getUsersQuery.data && (
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="text-left text-sm font-semibold text-gray-900">
                <th className="py-3.5 px-3">User</th>
                <th className="py-3.5 px-3">Tour</th>
                <th className="py-3.5 px-3">Date</th>
                <th className="py-3.5 px-3">Sampled time</th>
                <th className="py-3.5 px-3">Active</th>
                <th className="py-3.5 px-3">Patched at</th>
                <th className="py-3.5 px-3">Vacancies</th>
                <th className="py-3.5 px-3">Guests</th>
                <th className="py-3.5 px-3">Capacity</th>
                <th className="py-3.5 px-3">Available</th>
                <th className="py-3.5 px-3">Status</th>
                <th className="py-3.5 px-3"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {getAvailabilityPatchesQuery.data.map((item) => {
                const user = getUsersQuery.data.find((user) => user.id === item.userId) || {
                  email: 'unknown',
                  id: 'unknown',
                  name: 'Unknown',
                  locations: [],
                }
                return (
                  <tr key={item.id} className="whitespace-nowrap text-sm text-gray-500">
                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                      <div>{user.name}</div>
                      <div className="text-xs">{user.email}</div>
                    </td>
                    <td className="py-4 px-3">{formatSessionCode(item.sessionCode)}</td>
                    <td className="py-4 px-3">{formatDate(item.tourDate)}</td>
                    <td className="py-4 px-3">{item.sampledTime}</td>
                    <td className="py-4 px-3">
                      {item.active ? <Badge color="green">Yes</Badge> : <Badge color="red">No</Badge>}
                    </td>
                    <td className="py-4 px-3">{formatTimestamp(moment.tz(item.createdAt, timeZone))}</td>
                    <td className="py-4 px-3">
                      <div>{formatNilable(item.patch.vacancies)}</div>
                      <div>{formatNilable(item.original.vacancies)}</div>
                    </td>
                    <td className="py-4 px-3">
                      <div>{formatNilable(item.patch.guestQty)}</div>
                      <div>{formatNilable(item.original.guestQty)}</div>
                    </td>
                    <td className="py-4 px-3">
                      <div>{formatNilable(item.patch.capacity)}</div>
                      <div>{formatNilable(item.original.capacity)}</div>
                    </td>
                    <td className="py-4 px-3">
                      <div>
                        <ColoredBoolean value={item.patch.available} />
                      </div>
                      <div>
                        <ColoredBoolean value={item.original.available} />
                      </div>
                    </td>
                    <td className="py-4 px-3">
                      <div>
                        <ColoredStatus value={item.patch.status} />
                      </div>
                      <div>
                        <ColoredStatus value={item.original.status} />
                      </div>
                    </td>
                    <td className="py-4 px-3 font-semibold  text-gray-900">
                      <div>Patch</div>
                      <div>Original</div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  )
}
