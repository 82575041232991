import {map} from 'lodash'
import {entities} from '../commonTypes'
import {Option} from '../commonTypes/entities'
import {getColors} from '../helpers/getColors'

type Props = {
  options: Option[]
  filterKey: string
  filterValue: entities.ListFilterValue
  changeFilter: (value: entities.ListFilterValue) => void
  withColors?: boolean
}

export const OptionFilter = ({filterKey, filterValue, changeFilter, options, withColors}: Props) => {
  const allChecked = options.length === filterValue.length
  const allOptionValues = map(options, 'value')

  const onChangeAll = () => changeFilter(allChecked ? [] : allOptionValues)

  const onChange = (value: string, checked: boolean) => {
    const newFilterValue = allOptionValues.filter((optionValue) => {
      return checked
        ? filterValue.includes(optionValue) || optionValue === value
        : filterValue.includes(optionValue) && optionValue !== value
    })

    return changeFilter(newFilterValue)
  }

  const allInputId = `all-${filterKey}`

  const colors = withColors ? getColors(options) : undefined

  return (
    <div className="flex flex-col gap-3 ml-3">
      {options.length > 0 && (
        <div className="relative flex items-start">
          <div className="flex items-center h-5">
            <input
              id={allInputId}
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              onChange={onChangeAll}
              checked={allChecked}
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor={allInputId} className="font-medium text-gray-700">
              All
            </label>
          </div>
        </div>
      )}
      {options.length === 0 && <div className="text-sm font-medium">No items</div>}
      {options.map((option) => {
        const checked = !filterValue || filterValue.includes(option.value)

        const style = colors
          ? {
              borderColor: 'gray',
              color: colors(option.value),
            }
          : undefined

        return (
          <div className="relative flex items-start" key={option.value}>
            <div className="flex items-center h-5">
              <input
                id={option.value}
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-600 rounded"
                style={style}
                checked={checked}
                onChange={() => onChange(option.value, !checked)}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={option.value} className="font-medium text-gray-700">
                {option.label}
              </label>
            </div>
          </div>
        )
      })}
    </div>
  )
}
