import {round} from 'lodash'
import {Badge} from './Badge'

type Props = {
  rate: number | null
}

export const RateBadge = ({rate}: Props) => {
  if (rate === null) {
    return <span>-</span>
  }

  const roundedRate = round(rate)

  if (roundedRate < 50) {
    return <Badge color="red">{roundedRate}%</Badge>
  }

  if (roundedRate < 80) {
    return <Badge color="orange">{roundedRate}%</Badge>
  }

  return <Badge color="green">{roundedRate}%</Badge>
}
