import {ReactNode} from 'react'
import {AccordionItemProps} from './Accordion'
import {CollapseButton} from './CollapseButton'

type Props = AccordionItemProps & {
  title: string
  children: ReactNode
}

export const FilterAccordionItem = ({title, setOpen, open, children}: Props) => {
  return (
    <div className="flex flex-col gap-3">
      <CollapseButton title={title} onClick={() => setOpen(!open)} collapsed={!open} />
      {open && <div className="ml-3 flex flex-col gap-3 pb-3">{children}</div>}
    </div>
  )
}
