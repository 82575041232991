import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/24/solid'
import cx from 'classnames'

type Props = {
  title: string
  onClick: () => void
  type?: 'primary' | 'secondary' | 'white'
  className?: string
  disabled?: boolean
  color?: string
  collapsed: boolean
}

export const CollapseButton = ({
  type = 'primary',
  title,
  onClick,
  className,
  disabled,
  color = 'indigo',
  collapsed,
}: Props) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className={cx(
        `justify-between h-min inline-flex items-center px-4 py-2.5 border text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500 disabled:opacity-50`,
        {
          [`border-transparent shadow-sm text-white bg-${color}-600 hover:bg-${color}-700`]: type === 'primary',
          [`border-transparent text-${color}-700 bg-${color}-100 hover:bg-${color}-200`]: type === 'secondary',
          'border-gray-300 shadow-sm text-gray-700 bg-white hover:bg-gray-50': type === 'white',
        },
        className
      )}
      onClick={onClick}
    >
      <span>{title}</span>
      {collapsed ? <ChevronDownIcon className="h-5 w-5" /> : <ChevronUpIcon className="h-5 w-5" />}
    </button>
  )
}
