import cx from 'classnames'
import {ReactNode, useLayoutEffect, useRef, useState} from 'react'

export type TooltipPosition = {
  left: number
  top: number
}

type Props = {
  visibility: boolean
  position: TooltipPosition | null | undefined
  children: ReactNode
}

const PADDING = 15

const fixValue = (screen: number, tooltip: number, position: number) => {
  return position + tooltip + PADDING > screen ? screen - tooltip : position + PADDING
}

const positionToStyle = (div: HTMLDivElement, position: TooltipPosition) => {
  return {
    left: fixValue(document.documentElement.clientWidth, div.clientWidth, position.left),
    top: fixValue(document.documentElement.clientHeight, div.clientHeight, position.top),
  }
}

export const Tooltip = ({children, visibility, position}: Props) => {
  const tooltipRef = useRef(null)
  const [style, setStyle] = useState({left: 0, top: 0})

  useLayoutEffect(() => {
    if (!position || !tooltipRef || !tooltipRef.current) {
      return
    }

    setStyle(positionToStyle(tooltipRef.current, position))
  }, [children, position])

  return (
    <div
      ref={tooltipRef}
      className={cx(
        'whitespace-pre fixed bg-white border rounded p-2 text-sm grid grid-cols-[auto_auto] gap-x-3 gap-y-2 items-center',
        {hidden: !visibility}
      )}
      style={style}
    >
      {children}
    </div>
  )
}
