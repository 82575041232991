import {groupBy, map, uniq} from 'lodash'
import {entities} from '../commonTypes'
import {useCategoryOptions, useLocationOptions, useProductOptions, useSessionOptions} from '../contexts/app'
import {useReportFiltersContext} from '../contexts/reportFilters'
import {formatDate} from '../helpers/formatters'

type Formatter = (filter: entities.ListFilterValue, options: entities.Option[]) => string

const defaultFormatter = (filter: entities.ListFilterValue, options: entities.Option[]) => {
  const optionMap = options.reduce((result: Record<string, string>, option) => {
    result[option.value] = option.label
    return result
  }, {})

  return filter.map((value) => optionMap[value] || value).join(', ')
}

const toursFormatter = (filter: entities.ListFilterValue, options: entities.Option[]) => {
  const optionMap = options.reduce((result: Record<string, string>, option) => {
    result[option.value] = option.label
    return result
  }, {})

  const parsedValues = filter.map((value) => {
    const [product, startTime] = optionMap[value].split(' ')
    return {product, startTime}
  })

  const products = uniq(parsedValues.map(({product}) => product))

  const groups = groupBy(parsedValues, ({product}) => product)

  return products.map((product) => `${product} (${map(groups[product], 'startTime').join(', ')})`).join(', ')
}

const formatListFilter = (
  filter: entities.ListFilterValue,
  options: entities.Option[] = [],
  formatter: Formatter = defaultFormatter
) => {
  if (filter.length === options.length) {
    return 'Selected all'
  }

  if (filter.length === 0) {
    return 'Selected nothing'
  }

  return formatter(filter, options)
}

export const FiltersInfo = () => {
  const {filters} = useReportFiltersContext()
  const locationOptions = useLocationOptions()
  const categoryOptions = useCategoryOptions()
  const productOptions = useProductOptions(filters)
  const sessionOptions = useSessionOptions(filters)

  return (
    <div className="text-sm grid grid-cols-[100px_1fr] gap-1 w-fit p-3">
      <span className="font-semibold">From</span>
      <span>{formatDate(filters.from)}</span>

      <span className="font-semibold">To</span>
      <span>{formatDate(filters.to)}</span>

      <span className="font-semibold">Destinations</span>
      <span>{formatListFilter(filters.locations, locationOptions)}</span>

      <span className="font-semibold">Categories</span>
      <span>{formatListFilter(filters.categories, categoryOptions)}</span>

      <span className="font-semibold">Products</span>
      <span>{formatListFilter(filters.products, productOptions)}</span>

      <span className="font-semibold">Tours</span>
      <span>{formatListFilter(filters.sessions, sessionOptions, toursFormatter)}</span>
    </div>
  )
}
