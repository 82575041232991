import {useCallback, useState} from 'react'

const d3 = require('d3')

export const useContainer = () => {
  const [container, setContainer] = useState<any>(null)

  const containerRef = useCallback((node: any) => {
    if (node !== null) {
      setContainer(d3.select(node))
    }
  }, [])

  return [container, containerRef]
}
