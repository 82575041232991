import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {Dashboard} from './Dashboard'
import {Loader} from './components/Loader'
import {AppProvider} from './contexts/app'
import {useIsLoading} from './hooks/useIsLoading'

export const App = () => {
  const isLoading = useIsLoading()

  return (
    <BrowserRouter>
      {isLoading && <Loader />}
      <AppProvider>
        <Routes>
          <Route path="/app/*" element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/app/soldoutDays" replace />} />
        </Routes>
      </AppProvider>
    </BrowserRouter>
  )
}
