import {User} from '@exp1/common-utils'
import {FilterOptions, Location} from 'commonTypes/entities'
import {ReactNode, createContext, useContext, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import api from '../api'
import {filterProductOptions} from '../utils/filterProductOptions'
import {filterSessionOptions} from '../utils/filterSessionOptions'
import {FiltersState} from './reportFilters'

type Props = {
  children: ReactNode
}

type Value = {
  user: User
  filterOptions: FilterOptions
  locations: Location[]
}

export const AppContext = createContext<Value | null>(null)

export const useAppContext = () => {
  const app = useContext(AppContext)

  if (app === null) {
    throw new Error('App state is null')
  }

  return app
}

export const useUser = () => {
  const app = useAppContext()

  if (app.user === null) {
    throw new Error('User is null')
  }

  return app.user
}

export const useLocations = () => {
  const app = useAppContext()

  if (app.locations === null) {
    throw new Error('Locations is null')
  }

  return app.locations
}

export const usePrimaryLocation = () => {
  const user = useUser()
  const locations = useLocations()
  const primaryLocationCode = user.locations.length > 0 ? user.locations[0] : 'NYC'
  return locations.find((location) => location.code === primaryLocationCode)
}

export const useFilterOptions = () => {
  const app = useAppContext()

  if (app.filterOptions === null) {
    throw new Error('Filter options is null')
  }

  return app.filterOptions
}

export const useSessionOptions = ({
  locations,
  categories,
  products,
}: Pick<FiltersState, 'locations' | 'categories' | 'products'>) => {
  const {sessionOptions} = useFilterOptions()
  return useMemo(
    () => filterSessionOptions(sessionOptions, {locations, categories, products}),
    [sessionOptions, locations, categories, products]
  )
}

export const useProductOptions = ({locations, categories}: Pick<FiltersState, 'locations' | 'categories'>) => {
  const {productOptions} = useFilterOptions()
  return filterProductOptions(productOptions, {locations, categories})
}

export const useCategoryOptions = () => {
  const {categoryOptions} = useFilterOptions()
  return categoryOptions
}

export const useLocationOptions = () => {
  const {locationOptions} = useFilterOptions()
  return locationOptions
}

export const AppProvider = ({children}: Props) => {
  const [user, setUser] = useState<User | null>(null)
  const [filterOptions, setFilterOptions] = useState<FilterOptions | null>(null)
  const [locations, setLocations] = useState<Location[] | null>(null)

  useQuery(['iAm'], () => api.iAm(), {
    onSuccess: setUser,
  })

  useQuery(['filterOptions'], () => api.getFilterOptions(), {
    onSuccess: setFilterOptions,
  })

  useQuery(['locations'], () => api.getLocations(), {
    onSuccess: setLocations,
  })

  const value =
    user && filterOptions && locations
      ? {
          user,
          filterOptions,
          locations,
        }
      : null

  return <AppContext.Provider value={value}>{value ? children : null}</AppContext.Provider>
}
