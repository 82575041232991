import {useState} from 'react'
import {useListSidebarFilter} from '../contexts/sidebarFilters'
import {CollapsableOptionFilter} from './CollapsableOptionFilter'
import { useCategoryOptions } from '../contexts/app'

export const CategoryFilter = () => {
  const categoryFilter = useListSidebarFilter('categories')
  const [open, setOpen] = useState(false)

  const options = useCategoryOptions()

  return (
    <CollapsableOptionFilter
      open={open}
      setOpen={setOpen}
      options={options}
      filterValue={categoryFilter.value}
      changeFilter={categoryFilter.change}
      title="Filter category"
      filterKey="categories"
    />
  )
}
