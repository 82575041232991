import {cloneDeep, max, mean, min, round} from 'lodash'
import {useEffect, useState} from 'react'
import {entities} from '../../commonTypes'
import {useSessionOptions} from '../../contexts/app'
import {useReportFiltersContext} from '../../contexts/reportFilters'
import {formatChartDate, formatDate, formatSampleTime, formatSessionCode} from '../../helpers/formatters'
import {getColors} from '../../helpers/getColors'
import {getDateGroups} from '../../helpers/getDateGroups'
import {useContainer} from '../../hooks/useContainer'
import {useGetCapacityRateQuery} from '../../hooks/useGetCapacityRateQuery'
import {convertRecordsToCapacityRateSessionReports} from '../../utils/convertRecordsToCapacityRateSessionReports'
import {Tooltip, TooltipPosition} from '../Tooltip'
import {TooltipItem} from '../TooltipItem'

const d3 = require('d3')

type TooltipDetails = {
  capacity: number | null | undefined
  rate: number | null | undefined
  tourDate: string
  tour: string
  sampledTime: number
  guestQuantity: number | null | undefined
  guideQuantity: number | null | undefined
}

const DEFAULT_TOOLTIP_DETAILS: TooltipDetails = {
  capacity: null,
  rate: null,
  tourDate: '-',
  tour: '-',
  guestQuantity: null,
  guideQuantity: null,
  sampledTime: 0,
}

const PADDING = 50

const getRangeOfCapacityRate = (records: entities.SessionSampledCapacity[]) => {
  const rates = records.map((record) => record.rate)
  return rates.length === 0 ? [0, 0, 0] : [min(rates), max(rates), mean(rates)]
}

export const CapacityRateChart = () => {
  const {filters: reportFilters} = useReportFiltersContext()
  const [svg, svgRef] = useContainer()

  const getCapacityRateQuery = useGetCapacityRateQuery(reportFilters)
  const sessionOptions = useSessionOptions(reportFilters)

  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition | null>(null)
  const [tooltipDetails, setTooltipDetails] = useState<TooltipDetails>(DEFAULT_TOOLTIP_DETAILS)

  useEffect(() => {
    if (svg === null) {
      return
    }

    if (!getCapacityRateQuery.data) {
      return
    }

    svg.selectAll('*').remove()

    const allRecords = getCapacityRateQuery.data.records
      .filter((record) => record.rate !== null && !record.ignore)
      .map((record) => {
        return {
          ...record,
          tourDate: formatChartDate(record.tourDate),
        }
      })

    const sessionReports = convertRecordsToCapacityRateSessionReports(allRecords)

    const colors = getColors(sessionOptions)

    const groups = getDateGroups(reportFilters.from!, reportFilters.to!)
    const [_, maxY] = getRangeOfCapacityRate(allRecords)

    const {clientWidth, clientHeight} = svg.node()

    const width = clientWidth - 2 * PADDING
    const height = clientHeight - 2 * PADDING

    const container = svg.append('g').attr('transform', `translate(${PADDING}, ${PADDING})`)

    const x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2])

    const y = d3.scaleLinear().domain([0, maxY]).range([height, 0])

    const xAxis = d3.axisBottom(x)
    const yAxis = d3.axisLeft(y)

    container.append('g').attr('transform', `translate(0, ${height})`).call(xAxis)

    container
      .append('text')
      .attr('transform', `translate(${width / 2}, ${height + (PADDING * 2) / 3})`)
      .style('font-weight', 'bold')
      .style('font-size', '12px')
      .style('fill', 'black')
      .style('text-anchor', 'middle')
      .text('Date')

    container.append('g').attr('transform', `translate(0, 0)`).call(yAxis)

    container
      .append('text')
      .attr('transform', `translate(${-PADDING / 2}, ${height / 2})  rotate(-90)`)
      .style('font-size', '12px')
      .style('font-weight', 'bold')
      .style('fill', 'black')
      .style('text-anchor', 'middle')
      .text('Capacity rate, %')

    for (const tourReport of cloneDeep(sessionReports)) {
      container
        .append('path')
        .datum(tourReport.dateReports)
        .attr('fill', 'none')
        .attr('stroke', colors(tourReport.sessionCode))
        .attr('stroke-width', 1.5)
        .attr(
          'd',
          d3
            .line()
            .x((d: any) => x(d.tourDate) + x.bandwidth() / 2)
            .y((d: any) => y(d.rate))
        )

      container
        .append('g')
        .selectAll('dot')
        .data(tourReport.dateReports)
        .enter()
        .append('circle')
        .attr('cx', (d: any) => x(d.tourDate) + x.bandwidth() / 2)
        .attr('cy', (d: any) => y(d.rate))
        .attr('r', 5)
        .attr('fill', () => colors(tourReport.sessionCode))
        .attr('stroke', 'white')
        .on('mouseenter', (e: any) => {
          const {clientX, clientY, target} = e
          const {rate, tourDate, guideQty, guestQty, capacity, sampledTime} = target.__data__

          setTooltipPosition({
            left: clientX,
            top: clientY,
          })

          setTooltipDetails({
            tour: formatSessionCode(tourReport.sessionCode),
            tourDate: formatDate(tourDate),
            guideQuantity: guideQty,
            guestQuantity: guestQty,
            capacity,
            rate: round(rate),
            sampledTime,
          })
        })
        .on('mouseleave', () => setTooltipPosition(null))
    }
  }, [svg, getCapacityRateQuery.data, sessionOptions, reportFilters.from, reportFilters.to])

  return (
    <div className="relative">
      <svg ref={svgRef} className="w-full h-96" />
      <Tooltip visibility={!!tooltipPosition} position={tooltipPosition}>
        <TooltipItem label="Tour" value={tooltipDetails.tour} />
        <TooltipItem label="Date" value={tooltipDetails.tourDate} />
        <TooltipItem label="Sampled time" hint="Last available" value={formatSampleTime(tooltipDetails.sampledTime)} />
        <TooltipItem label="Guides" hint="From guide cal." value={tooltipDetails.guideQuantity} />
        <TooltipItem label="Guests" hint="From Ventrata API" value={tooltipDetails.guestQuantity} />
        <TooltipItem label="Capacity" hint="From Ventrata API" value={tooltipDetails.capacity} />
        <TooltipItem label="Rate" hint="Calculated" value={tooltipDetails.rate} unit="%" />
      </Tooltip>
    </div>
  )
}
