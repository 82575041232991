import {Modal} from 'flowbite-react'
import {omit} from 'lodash'
import React, {FC, useReducer} from 'react'
import {Form} from 'react-final-form'
import {formatId} from '../helpers/formatters'
import {useAddOrChangeEntityMutation} from '../hooks/useAddOrChangeEntityMutation'
import {useGetEntityQuery} from '../hooks/useGetEntityQuery'
import {AddEntity, BaseAddEntityParams, BaseEditEntityParams, EditEntity, GetEntity} from '../types'
import {Button} from './Button'
import {Failure} from './Failure'
import {Loader} from './Loader'

type EditModal<Payload, Id> = {
  type: 'edit'
  id: Id
  show: true
  payload: Payload
}

type CreateModal<Payload> = {
  type: 'create'
  show: true
  payload: Payload
}

type ClosedModal = {
  show: false
}

export type OpenedModalState<Payload, Id> = EditModal<Payload, Id> | CreateModal<Payload>
export type ModalState<Payload, Id> = OpenedModalState<Payload, Id> | ClosedModal

type ContentProps<Payload, Id, Entity> = {
  state: OpenedModalState<Payload, Id>
  onClose: () => void
  onSave: (entity: Entity) => void
}

type EditTitle<Payload, Id, Entity> = string | ((state: EditModal<Payload, Id>, entity: Entity | undefined) => string)
type AddTitle<Payload> = string | ((state: CreateModal<Payload>) => string)

type ModalProps<
  Payload,
  Id,
  Entity,
  AddParams extends BaseAddEntityParams,
  EditParams extends BaseEditEntityParams<Id>
> = {
  state: ModalState<Payload, Id>
  onClose: () => void
  onSave: (entity: Entity) => void
  content: FC<ContentProps<Payload, Id, Entity>>
  getEntity: GetEntity<Entity, Id>
  addEntity?: AddEntity<Entity, AddParams>
  editEntity?: EditEntity<Entity, Id, EditParams>
  formInitialValues: Record<string, any> | (() => Record<string, any>)
  entityName: string
  editTitle?: EditTitle<Payload, Id, Entity>
  addTitle?: AddTitle<Payload>
}

type OpenedEntityModalProps<
  Payload,
  Id,
  Entity,
  AddParams extends BaseAddEntityParams,
  EditParams extends BaseEditEntityParams<Id>
> = Omit<ModalProps<Payload, Id, Entity, AddParams, EditParams>, 'state'> & {
  state: OpenedModalState<Payload, Id>
}

const getAddTitle = <Payload,>(state: CreateModal<Payload>, entityName: string, addTitle?: AddTitle<Payload>) => {
  if (!addTitle) {
    return `Add ${entityName}`
  }

  if (typeof addTitle === 'function') {
    return addTitle(state)
  }

  return addTitle
}

const getEditTitle = <Payload, Id, Entity>(
  state: EditModal<Payload, Id>,
  entityName: string,
  entity: Entity | undefined,
  editTitle?: EditTitle<Payload, Id, Entity>
) => {
  if (!editTitle) {
    return `Edit ${entityName} ${formatId(state.id)}`
  }

  if (typeof editTitle === 'function') {
    return editTitle(state, entity)
  }

  return editTitle
}

export const OpenedEntityModal = <
  Payload,
  Id,
  Entity,
  AddParams extends BaseAddEntityParams,
  EditParams extends BaseEditEntityParams<Id>
>({
  getEntity,
  addEntity,
  editEntity,
  entityName,
  state,
  content: Content,
  onSave,
  onClose,
  formInitialValues,
  addTitle,
  editTitle,
}: OpenedEntityModalProps<Payload, Id, Entity, AddParams, EditParams>) => {
  const resultAddEntity = addEntity
    ? addEntity
    : () => {
        throw new Error('addEntity() is not defined')
      }
  const resultEditEntity = editEntity
    ? editEntity
    : () => {
        throw new Error('editEntity() is not defined')
      }

  const {query: getEntityQuery, invalidate: invalidateGetEntityQuery} = useGetEntityQuery(
    state.type === 'edit' ? state.id : undefined,
    getEntity,
    entityName
  )
  const addOrChangeEntityMutation = useAddOrChangeEntityMutation(resultAddEntity, resultEditEntity)

  const onSubmit = async (values: any) => {
    if (!state.show) {
      throw new Error()
    }

    const params = state.type === 'edit' ? {...values, id: state.id} : values
    await addOrChangeEntityMutation.mutateAsync(params, {
      onSuccess: onSave,
    })
    await invalidateGetEntityQuery()
    onClose()
  }

  const initialValues = getEntityQuery.data
    ? getEntityQuery.data
    : typeof formInitialValues === 'function'
    ? formInitialValues()
    : formInitialValues
  const error = addOrChangeEntityMutation.error || getEntityQuery.error
  const loading = addOrChangeEntityMutation.isLoading || getEntityQuery.isLoading

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({handleSubmit}) => (
        <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
          <Modal.Header>
            {state.type === 'edit'
              ? getEditTitle(state, entityName, getEntityQuery.data, editTitle)
              : getAddTitle(state, entityName, addTitle)}
          </Modal.Header>
          <Modal.Body>
            <Content state={state} onClose={onClose} onSave={onSave} />
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" title="Save" />
            <Button color="gray" title="Cancel" onClick={onClose} />
            {error ? <Failure error={error} /> : null}
          </Modal.Footer>
          {loading && <Loader />}
        </form>
      )}
    />
  )
}

export const EntityModal = <
  Payload,
  Id,
  Entity,
  AddParams extends BaseAddEntityParams,
  EditParams extends BaseEditEntityParams<Id>
>(
  props: ModalProps<Payload, Id, Entity, AddParams, EditParams>
) => {
  return (
    <Modal show={props.state.show} onClose={props.onClose}>
      {props.state.show && <OpenedEntityModal state={props.state} {...omit(props, 'state')} />}
    </Modal>
  )
}

type CreateAction<Payload> = {
  type: 'create'
  payload: Payload
}

type EditAction<Payload, Id> = {
  type: 'edit'
  id: Id
  payload: Payload
}

type CloseAction = {
  type: 'close'
}

type Action<Payload, Id> = CreateAction<Payload> | EditAction<Payload, Id> | CloseAction

EntityModal.useManager = function <Payload, Id>() {
  const modalStateReducer = (prev: ModalState<Payload, Id>, action: Action<Payload, Id>): ModalState<Payload, Id> => {
    switch (action.type) {
      case 'create':
        return {
          type: 'create',
          show: true,
          payload: action.payload,
        }
      case 'edit':
        return {
          id: action.id,
          type: 'edit',
          show: true,
          payload: action.payload,
        }
      case 'close':
        return {
          show: false,
        }
    }
  }

  const [state, dispatch] = useReducer(modalStateReducer, {show: false})

  return {
    state,
    create: (payload: Payload) => dispatch({type: 'create', payload}),
    edit: (id: Id, payload: Payload) => dispatch({type: 'edit', id, payload}),
    close: () => dispatch({type: 'close'}),
  }
}

EntityModal.useManagerWithoutPayload = function <Id>() {
  const manager = EntityModal.useManager<undefined, Id>()

  return {
    state: manager.state,
    create: () => manager.create(undefined),
    edit: (id: Id) => manager.edit(id, undefined),
    close: manager.close,
  }
}
