import {CapacityRateChart} from '../components/charts/CapacityRateChart'
import {CapacityRateReport} from '../components/reports/CapacityRateReport'
import {SummaryCapacityRateReport} from '../components/reports/SummaryCapacityRateReport'

export const CapacityRate = () => {
  return (
    <>
      <SummaryCapacityRateReport />
      <CapacityRateChart />
      <CapacityRateReport />
    </>
  )
}
