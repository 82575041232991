import moment, {Moment} from 'moment/moment'
import {entities} from '../commonTypes'
import {DISPLAY_CHART_FORMAT, DISPLAY_FORMAT, DISPLAY_WITH_DAY_FORMAT} from '../types'

export const formatVacancies = (vacancies: number | null | undefined, closed?: boolean) => {
  if (closed === true) {
    return '×'
  }

  return vacancies === null ? '∞' : vacancies
}

export const formatAvailabilityDetails = ({vacancies, status, available}: entities.AvailabilityDetails) => {
  const formattedVacancies = formatVacancies(vacancies)

  if (status === 'CLOSED') {
    return `× ${formattedVacancies}`
  }

  if (available === false) {
    return `∅ ${formattedVacancies}`
  }

  return formattedVacancies
}

export const formatSessionCode = (sessionCode: string) => {
  return sessionCode.replace('|', ' ')
}

export const formatStatus = (value: 'CLOSED' | 'NOT_CLOSED' | null | undefined) => {
  return formatNilable(value, (value) => (value === 'CLOSED' ? 'Closed' : 'Not closed'))
}

export const formatSampleTime = (sampleTime: number) => {
  if (sampleTime === -1) {
    return 'now'
  }

  if (sampleTime === 0) {
    return 'start'
  }

  return sampleTime
}

export const formatNilable = (value: any, formatter?: (value: any) => any) => {
  if (value === null || value === undefined) {
    return '-'
  }

  return formatter ? formatter(value) : value
}

export const formatDate = (date: Moment | string | undefined | null, withDay: boolean = false) => {
  if (!date) {
    return '—'
  }

  const momentDate = typeof date === 'string' ? moment(date) : date

  return moment(momentDate).format(withDay ? DISPLAY_WITH_DAY_FORMAT : DISPLAY_FORMAT)
}

export const formatChartDate = (date: Moment | string | undefined | null) => {
  if (!date) {
    return '—'
  }

  const momentDate = typeof date === 'string' ? moment(date) : date

  return moment(momentDate).format(DISPLAY_CHART_FORMAT)
}

export const formatTimestamp = (date: Moment | string | undefined | null) => {
  if (!date) {
    return '—'
  }

  const momentDate = typeof date === 'string' ? moment(date) : date

  return moment(momentDate).format(DISPLAY_WITH_DAY_FORMAT + ' HH:mm:ss')
}

export const formatBoolean = (value: boolean | null | undefined) => {
  return formatNilable(value, (value) => (value ? 'Yes' : 'No'))
}

export const formatId = (id: any) => {
  if (typeof id === 'object') {
    return Object.values(id).join(', ')
  }

  return JSON.stringify(id)
}
